import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareHeroBannerContainer, {
  EasySoftwareHeroBannerContainerType,
} from '../EasySoftwareHeroBannerContainer/EasySoftwareHeroBannerContainer'

export interface WidgetEasySoftwareHeroBannerContainerType
  extends WidgetBase,
  EasySoftwareHeroBannerContainerType {
  contentType: 'EasySoftwareHeroBannerContainer'
}

const WidgetEasySoftwareHeroBannerContainer: FunctionComponent<WidgetEasySoftwareHeroBannerContainerType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.metadata}
    fullWidth
    widgetAnimationPartIsEnabled={true}
    widgetAnimationPartType = {``}
  >
    <EasySoftwareHeroBannerContainer
      displayText={props.displayText}
      heroBannerContainerItems={props.heroBannerContainerItems}
      heroBannerContainerTheme={props.heroBannerContainerTheme}
      heroBannerContainerBackground={props.heroBannerContainerBackground}
      pageContentItemId={props.pageContentItemId}
      editorPath={props.editorPath}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareHeroBannerContainer
