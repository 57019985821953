import './KnowledgeBaseArticlePart.scss'

import PuxLink from 'Shared/utils/PuxLink'
import React, { FunctionComponent } from 'react'
import { getLocalizedUrl } from 'Shared/utils/localeURL'

export interface KnowledgeBaseArticlePartType {
  contentType: 'EasySoftwareKnowledgeBaseArticle'
  contentItemId: string
  path: string
  displayText: string
}

const KnowledgeBaseArticlePart: FunctionComponent<KnowledgeBaseArticlePartType> = (
  props
) => {
  return (
    <div className='KnowledgeBaseArticle-item'>
      <PuxLink to={`${getLocalizedUrl(props.path)}`}>{props.displayText}</PuxLink>
    </div>
  )
}

export default KnowledgeBaseArticlePart
