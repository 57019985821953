import './EasySoftwareTestimonials.scss'

import React, { FunctionComponent } from 'react'
import PuxPicture, {
  PuxPictureType,
} from 'Shared/components/content/mediaImage/PuxPicture'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareTestimonialType {
  contentType: string
  contentItemId: string
  testimonialName: PuxWysiwygHtmlBodyType
  testimonialOccupation: PuxWysiwygHtmlBodyType
  testimonialCompany: PuxWysiwygHtmlBodyType
  testimonialText: PuxWysiwygHtmlBodyType
  testimonialPhoto: {
    mediaSelectorImage: PuxPictureType
  }
  pageContentItemId: string,
  editorPath: string
}

// const rootClass = `pux-testimonials`

const EasySoftwareTestimonialItem: FunctionComponent<EasySoftwareTestimonialType> = (
  props
) => {
  return (
    <div className='Testimonials-item'>
      <div className='Testimonials-meta'>
        <div className='Testimonials-img'>
          <PuxPicture
            {...props?.testimonialPhoto?.mediaSelectorImage}
            width={50}
            height={50}
            customAlt={props.testimonialName.html}
          />
        </div>
        <div className='Testimonials-profile'>
          <h4 className='Testimonials-name'>{props.testimonialName.html}</h4>
          <div className='Testimonials-info'>
            <div className='Testimonials-occupation'>
              {props.testimonialOccupation.html}
            </div>
            <div className='Testimonials-company'>
              {props.testimonialCompany.html}
            </div>
          </div>
        </div>
      </div>

      <div className='Testimonials-content'>
        <div className='Testimonials-icon'></div>
        <PuxWysiwyg
          className='Testimonials-text'
          content={props.testimonialText}
          inline
          wysiwygId={props.contentItemId}
        />
      </div>
    </div>
  )
}

export default EasySoftwareTestimonialItem