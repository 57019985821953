import React, { FunctionComponent } from 'react'

import { WidgetBase } from '../builders/WidgetBuilder'
import PuxSection, { PuxSectionType } from '../PuxSection/PuxSection'
// import PuxAnchor from 'Shared/components/PuxAnchor/PuxAnchor'
// import PuxModal from 'Shared/components/PuxModal/PuxModal'

export interface WidgetPuxSectionType extends WidgetBase, PuxSectionType {
  contentType: 'PuxSection'
}

const WidgetPuxSection: FunctionComponent<WidgetPuxSectionType> = (props) => {
  return <PuxSection {...props} />
}

export default WidgetPuxSection
