import React from 'react'

import { FormFieldText } from '../builders/FormBuilder'
import { WidgetProperitesPart } from '../builders/WidgetBuilder'
import { getClass } from './formFieldHelper'

const FormFieldTextWidget: React.FC<{
  props: FormFieldText
  widgetProperties?: WidgetProperitesPart
}> = ({ props, widgetProperties }) => {
  return (
    <div
      className={`FormGroup ${getClass(
        props?.metadata?.size,
        widgetProperties
      )} ${props?.puxFormElementOptions?.formElementOptionsCssClass ?? ``
        } FormGroup--text`}
      dangerouslySetInnerHTML={{ __html: props?.formFieldTextContext?.html }}
    />
  )
}

export default FormFieldTextWidget
