import { langConfig } from "Root/puxconfig"
import type { CreatePagesArgs } from "gatsby"

class BuilderService {
    protected _builderArgs: CreatePagesArgs
    protected _locale: langConfig
    protected _authorization: { token_type: string, access_token: string }
    protected _hasOrchardPrefix: boolean

    constructor(builderArgs: CreatePagesArgs, locale: langConfig, hasOrchardPrefix: boolean = true) {
        this._builderArgs = builderArgs
        this._locale = locale
        this._authorization = { token_type: "", access_token: "" }
        this._hasOrchardPrefix = hasOrchardPrefix
    }

    get builderArgs() {
        return this._builderArgs
    }

    get locale() {
        return this._locale
    }

    get authorization() {
        return this._authorization
    }

    get hasOrchardPrefix() {
        return this._hasOrchardPrefix
    }

    set locale(locale: langConfig) {
        this._locale = locale
    }

    set authorization(authorization: { token_type: string, access_token: string }) {
        if (authorization.token_type === "" || authorization.access_token === "") {
            throw "ERROR: Authorization token_type or access_token is empty in puxBuilderService.ts"
        }

        this._authorization = authorization
    }
}

let puxBuilderService: BuilderService | null = null

export function initBuilderService(builderArgs: CreatePagesArgs, locale: langConfig, hasOrchardPrefix: boolean = true) {
    if (puxBuilderService === null) {
        puxBuilderService = new BuilderService(builderArgs, locale, hasOrchardPrefix)
    }
}

export function getBuilderService() {
    if (puxBuilderService !== null) {
        return puxBuilderService
    }

    throw "ERROR: BuilderService instance is null in puxBuilderService.ts"
}
