import './EasySoftwareSideCardNavigation.scss'

import React, { FunctionComponent } from 'react'
import PuxNavigation, {
  ContentLinkType,
} from 'Shared/components/PuxNavigation/PuxNavigation'
import { KnowledgeBaseMenuItemType } from 'Shared/utils/getKnowledgeBaseData'

export interface SideCardNavigationLinkType {
  contentType?: string
  contentItemId?: string
  sideCardNavigationLinkItem: ContentLinkType
  sideCardNavigationLinkNewWindow: boolean
  sideCardNavigationLinkIcon: string
  widgetOrder?: number
}

interface SideCardNavigationContentItems {
  contentItems: SideCardNavigationLinkType[]
}

export interface EasySoftwareSideCardNavigationType {
  contentType: 'EasySoftwareSideCardNavigation'
  displayText: string
  contentItemId: string
  type: 'widget'
  sideCardNavigationLinks: SideCardNavigationContentItems
  widgetOrder?: number
}

export interface KnowledgeBaseSideCardNavigationType {
  type: 'knowledgeBase'
  sideCardNavigationLinks: KnowledgeBaseMenuItemType[]
}

type test =
  | EasySoftwareSideCardNavigationType
  | KnowledgeBaseSideCardNavigationType


const EasySoftwareSideCardNavigation: FunctionComponent<test> = (props) => {
  switch (props.type) {
    case `knowledgeBase`:
      return (
        <div className='Sidebar-navigation'>
          <PuxNavigation
            type={props.type}
            items={props.sideCardNavigationLinks}
          />
        </div>
      )
    case `widget`:
      return (
        <div className='Sidebar-navigation'>
          <PuxNavigation
            type={props.type}
            items={props.sideCardNavigationLinks?.contentItems}
          />
        </div>
      )
    default:
      return null
  }
}

export default EasySoftwareSideCardNavigation
