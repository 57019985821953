import { getBuilderService } from './data/puxBuilderService'
import { puxGraphqlHelper } from './data/puxGraphqlHelper'

export async function getBreadcrumbs(
  data: any,
  path: string,
  displayText: string,
): Promise<any> {
  const { locale } = getBuilderService()

  if (data && data.taxonomyContentItemId && data.termContentItemIds[0]) {
    const query = `{
      orchard {
        breadcrumbsItem(
          culture: "${locale.isoCode.toLowerCase()}"
          taxonomyContentItemId: "${data.taxonomyContentItemId}"
          termContentItemId: "${data.termContentItemIds[0]}") {
            displayText
            path
          }
        }
      }`

    const nodes = await puxGraphqlHelper<any>(
      query,
      {
        errorMetadata: {
          fromFunction: `Error while running GraphQL query for breadcrumbs.`
        }
      }
    )

    if (nodes.error) {
      return
    }

    nodes.breadcrumbsItem.push({
      displayText: displayText,
      path: path,
    })

    return nodes.breadcrumbsItem
  } else {
    return null
  }
}
