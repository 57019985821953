const baseQuery = `
    contentType
    displayText
`

const queryFormElement = `
    puxFormElement {
        formElementDefaultValue
        formElementID
        formElementName
        formElementLabel
        formElementPlaceholder
    }
`

const queryFormOptions = `
    puxFormOptions {
        formAction
        formMethod
        formEncType
        formAntiForgeryToken
        formID
        formSuccessMessage {
          html
        }
        formCustomCssClass
        formShowLabels
        formRedirectUrl {
          internal
          url
        }
    }
`

const queryFormElementOptions = `
    puxFormElementOptions {
        formElementOptionsDisabled
        formElementOptionsAutofocus
        formElementOptionsAutocomplete
        formElementOptionsMultiple
        formElementOptionsStep
        formElementOptionsCssClass
        formElementOptionsReadOnly
    }
`

const queryFormElementValidation = `
    puxFormElementValidation {
        formElementValidationRequired
        formElementValidationPattern
        formElementValidationMin
        formElementValidationMax
        formElementValidationMinLength
        formElementValidationMaxLength
    }
`

const queryFormRecaptchaOptions = `
    puxFormRecaptchaOptions {
        formRecaptchaSiteKey
        formValidateRecaptcha
    }
`

const queryMetadata = `
    metadata {
        alignment
        size
    }
`

const queryFormFieldInput = `
... on Orchard_PuxFormFieldInput {
    ${baseQuery}
    inputType
    ${queryFormElement}
    ${queryFormElementOptions}
    ${queryFormElementValidation}
    ${queryMetadata}
  }
`

const queryFormFieldTextArea = `
... on Orchard_PuxFormFieldTextarea {
    ${baseQuery}
    textareaResize
    textareaRows
    ${queryFormElement}
    ${queryFormElementOptions}
    ${queryFormElementValidation}
    ${queryMetadata}
  }
`

const queryFormFieldMultiple = `
... on Orchard_PuxFormFieldMultiple {
    ${baseQuery}
    multipleOptions
    multipleType
    ${queryFormElement}
    ${queryFormElementOptions}
    ${queryFormElementValidation}
    ${queryMetadata}
  }
`

const queryFormFieldSubmit = `
... on Orchard_PuxFormFieldSubmit {
    ${baseQuery}
    formElementName
    buttonColor
    buttonSize
    buttonType
    ${queryMetadata}
  }
`

const queryFieldText = `
... on Orchard_PuxFormFieldText {
    ${baseQuery}
    formFieldTextContext {
      html
    }
    ${queryMetadata}
    ${queryFormElementOptions}
  }
`

export const getFormQuery = (): string => {
  return `
    ... on Orchard_PuxForm {
        ${baseQuery}
        contentType
        ${queryMetadata}
        ${queryFormOptions}
        ${queryFormRecaptchaOptions}
        puxWidgetProperties {
            widgetPropertiesPartID
            widgetPropertiesPartMarginTop
            widgetPropertiesPartPaddingTop
            widgetPropertiesPartMarginBottom
            widgetPropertiesPartPaddingBottom
            widgetPropertiesPartMobileOrder
        }
        flow {
          widgets {
            ${queryFormFieldInput}
            ${queryFormFieldTextArea}
            ${queryFieldText}
            ${queryFormFieldSubmit}
            ${queryFormFieldMultiple}
          }
        }
    }
    `
}
