import { Modal } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FunctionComponent, useState } from 'react'
import { ChangeLogType } from 'Shared/utils/getChangeLogData'
import { t } from 'ttag'

export interface ChangeLogTableRowType {
  data: ChangeLogType
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: `absolute`,
      width: 820,
      backgroundColor: theme.palette.background.paper,
      boxShadow: `7px 7px 50px rgba(80, 89, 111, 0.3)`,
      padding: theme.spacing(2, 4, 3),
    },
  })
)

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const ChangeLogRowTable: FunctionComponent<ChangeLogTableRowType> = (props) => {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [isOpened, setIsOpened] = useState(false)

  const handleClose = () => {
    setIsOpened(false)
  }

  const handleOpen = () => {
    setIsOpened(true)
  }

  return (
    <>
      <td>{props.data.type}</td>
      <td>
        {props.data.description === `` && props.data.subject}
        {props.data.description !== `` && (
          <>
            <button
              className='Button Button--link Button--blue Button--medium'
              onClick={() => handleOpen()}
              type='button'
            >
              {props.data.subject}
            </button>
            <Modal open={isOpened} onClose={handleClose}>
              <div
                className={`Modal-paper ${classes.paper}`}
                style={modalStyle}
              >
                <div className={`Modal-close`} onClick={handleClose}></div>
                <div className={`Modal-content`}>
                  <h3 className={`h4`}>{props.data.subject}</h3>
                  <div>
                    <strong>{t`Redmine.ChangeLog.Type`}:</strong>
                    {` `}
                    {props.data.type}
                  </div>
                  <div>
                    <strong>{t`Redmine.ChangeLog.Version`}:</strong>
                    {` `}
                    {props.data.subject}
                  </div>
                  <div>
                    <strong>{t`Redmine.ChangeLog.Build`}:</strong>
                    {` `}
                    {props.data.build}
                  </div>
                  <br />
                  <div
                    dangerouslySetInnerHTML={{ __html: props.data.description }}
                  />
                </div>
              </div>
            </Modal>
          </>
        )}
      </td>
      <td>{props.data.build}</td>
    </>
  )
}

export default ChangeLogRowTable
