import './EasySoftwareReviews.scss'

import React, { FunctionComponent } from 'react'
import PuxPicture, {
  PuxPictureType,
} from 'Root/Shared/components/content/mediaImage/PuxPicture'

import PuxCarousel from '../PuxCarousel/PuxCarousel'
import EasySoftwareReviewItem, {
  EasySoftwareReviewItemType,
} from './EasySoftwareReviewItem'

export interface EasySoftwareReviewsType {
  contentItemId?: string
  displayText: string
  reviewsText: string
  reviewsSlider: boolean
  reviewsImage: PuxPictureType
  reviewsList: {
    cultureContentItems: [EasySoftwareReviewItemType]
  }
}

const EasySoftwareReviews: FunctionComponent<EasySoftwareReviewsType> = (
  props
) => {
  const items = props.reviewsList?.cultureContentItems ?? []
  const slides = items?.map((item, idx) => (
    <EasySoftwareReviewItem key={idx} {...item} />
  ))

  return (
    <div className='Reviews'>
      <div className='Reviews-items'>
        {items?.length <= 2 && props.reviewsSlider ? (
          <EasySoftwareReviewItem {...items[0]} />
        ) : (
          <PuxCarousel
            contentItemId={props.contentItemId}
            slidesPerView={1}
            navigation
            pagination
            autoplay
            breakpoints={{
              992: {
                slidesPerView: 2,
              },
            }}
            slides={slides}
          />
        )}
      </div>

      <div className='Reviews-static'>
        <h4 className='Reviews-footer'>{props.reviewsText}</h4>
        <PuxPicture
          {...props.reviewsImage}
          width={103}
          height={56}
          customAlt={props.reviewsText}
        />
      </div>
    </div>
  )
}

export default EasySoftwareReviews
