// Import Swiper React components
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './PuxCarousel.scss'

import React, { FunctionComponent } from 'react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  AutoplayOptions,
  NavigationOptions,
  PaginationOptions,
  SwiperModule,
} from 'swiper/types'

export interface PuxCarouselType {
  contentItemId?: string
  slides: JSX.Element[]
  slidesPerView?: number
  navigation?: boolean
  pagination?: boolean
  autoplay?: boolean
  breakpoints?: any
}

const DEFAULT_SWIPER_BREAKPOINTS = {
  640: {
    autoHeight: false,
  },
}

const PuxCarousel: FunctionComponent<PuxCarouselType> = (props) => {
  const swiperSettings: SwiperModule[] = []
  const carouselClass = [`Carousel`]

  const navigationSettings: NavigationOptions = {
    prevEl: `.Carousel-navLeft`,
    nextEl: `.Carousel-navRight`,
  }

  const paginationSettings: PaginationOptions = {
    clickable: true,
    el: `.Carousel-bullets`,
    type: `bullets`,
    dynamicBullets: true,
    dynamicMainBullets: 1,
  }

  const autoplaySettings: AutoplayOptions = {
    delay: 9000,
    disableOnInteraction: false,
  }

  if (props.pagination && props.navigation) {
    carouselClass.push(`Carousel--paginationAndNavigation`)
    swiperSettings.push(Pagination)
    swiperSettings.push(Navigation)
  } else {
    if (props.pagination) {
      carouselClass.push(`Carousel--pagination`)
      swiperSettings.push(Pagination)
    }

    if (props.navigation) {
      carouselClass.push(`Carousel--navigation`)
      swiperSettings.push(Navigation)
    }
  }

  if (props.autoplay) {
    swiperSettings.push(Autoplay)
  }

  SwiperCore.use(swiperSettings)

  return (
    <div className={carouselClass.join(` `)}>
      {props.navigation && <div className={`Carousel-navLeft`} />}
      <div className={`Carousel-content`}>
        <Swiper
          spaceBetween={50}
          slidesPerView={props.slidesPerView ? props.slidesPerView : undefined}
          pagination={props.pagination ? paginationSettings : false}
          navigation={props.navigation ? navigationSettings : false}
          loop={true}
          autoHeight={true}
          allowTouchMove={true}
          autoplay={props.autoplay ? autoplaySettings : false}
          breakpoints={
            props.breakpoints ? props.breakpoints : DEFAULT_SWIPER_BREAKPOINTS
          }
        >
          {props.slides?.map((slide, index) => (
            <SwiperSlide key={index}>{slide}</SwiperSlide>
          ))}
        </Swiper>
      </div>
      {props.navigation && <div className={`Carousel-navRight`} />}
      {props.pagination && <div className={`Carousel-bullets`} />}
    </div>
  )
}

export default PuxCarousel
