import './EasySoftwareEmbeddedCode.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareEmbeddedCode, {
  EasySoftwareDangerousEmbeddedCodeType,
  EasySoftwareEmbeddedCodeType,
} from './EasySoftwareEmbeddedCode'

export interface EasySoftwareEmbeddedCodeContainerType {
  embeddedCodeContainerItems: {
    cultureContentItems: [
      EasySoftwareEmbeddedCodeType | EasySoftwareDangerousEmbeddedCodeType
    ]
  }
}

const rootClass = `embeddedcode-container`

const EasySoftwareEmbeddedCodeContainer: FunctionComponent<EasySoftwareEmbeddedCodeContainerType> = (
  props
) => (
  <div className={rootClass}>
    {props.embeddedCodeContainerItems.cultureContentItems?.map((contentItem, idx) => (
      <EasySoftwareEmbeddedCode
        {...contentItem}
        key={idx}
      ></EasySoftwareEmbeddedCode>
    ))}
  </div>
)

export default EasySoftwareEmbeddedCodeContainer
