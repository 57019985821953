import { getFormQuery } from "../../../Shared/queries/form-queries"
import { puxGraphqlHelper } from "./puxGraphqlHelper"
import { PuxFormType } from "../../../Shared/components/builders/FormBuilder"

interface ContentItemQueryResult {
    contentItem: PuxFormType
}

export const getFormWidgetFromFormContainer = async (contentItemId: string): Promise<PuxFormType> => {
    const contentItemQuery = `
        {
            orchard {
                contentItem(contentItemId: "${contentItemId}") {
                    ${getFormQuery()}
                }
            }
        }
    `

    const contentItemNode = await puxGraphqlHelper<ContentItemQueryResult>(contentItemQuery, {
        errorMetadata:
        {
            message: `Error while running GraphQL query in getWidgetsFromOrchardWidgetZone for contentItemId:${contentItemId}`,
        }
    })

    if (!contentItemNode?.contentItem) {
        throw `Error: contentItemNode.contentItem is null or undefined in getWidgetsFromOrchardWidgetZone for contentItemId:${contentItemId}`
    }

    return contentItemNode.contentItem
}