/* eslint-disable prettier/prettier */
import './EasySoftwareCaseStudies.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareCaseStudyItem, {
  EasySoftwareCaseStudyItemType,
} from './EasySoftwareCaseStudyItem'

export interface EasySoftwareCaseStudiesType {
  contentItemId?: string
  displayText: string
  caseStudiesList: {
    cultureContentItems: [EasySoftwareCaseStudyItemType]
  }
}

// const rootClass = `case-studies`

const EasySoftwareCaseStudies: FunctionComponent<EasySoftwareCaseStudiesType> = (
  props
) => {
  return (
    <div className='CaseStudies'>
      <div className='CaseStudies-items'>
        {props.caseStudiesList &&
          props.caseStudiesList.cultureContentItems &&
          props.caseStudiesList.cultureContentItems.map((item) => (
            <EasySoftwareCaseStudyItem key={item.contentItemId} {...item} />
          ))}
      </div>
    </div>
  )
}

export default EasySoftwareCaseStudies
