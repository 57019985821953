import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'
import { MediaSelectorImageType } from '../content/mediaImage/PuxPicture'
import EasySoftwareLottie from '../content/mediaAnimation/EasySoftwareLottie'
import EasySoftwareBackgroundVideo from '../content/mediaBackgroundVideo/EasySoftwareBackgroundVideo'

export interface WidgetPuxVideoContainerType extends WidgetBase {
  contentType: 'PuxVideoContainer'
  videoContainerList: {
    cultureContentItems: [
      {
        puxVideo: PuxVideoType
        lottieJSON: string
        lottieJSONMobile: string
        displayText: string,
        lottieImage: MediaSelectorImageType | null
        backgroundVideoFileWEBM: {
          paths: [string],
          type: [string]
        },
        backgroundVideoFileMP4: {
          paths: [string],
          type: [string]
        },
        backgroundVideoPlaceholder: {
          paths: [string]
        },
        backgroundVideoLoop: boolean,
        backgroundVideoAutoplay: boolean,
        backgroundVideoControls: boolean,
        backgroundVideoShowOnMobile: boolean,
      }
    ]
  }
}

const WidgetPuxVideoContainer: FunctionComponent<WidgetPuxVideoContainerType> = (
  props
) =>(
    <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
    >
    {props.videoContainerList?.cultureContentItems?.map((item, idx) => {
      if (item?.puxVideo) {
        return (
          <div className='video-container' key={idx}>
            <PuxVideo {...item.puxVideo} key={idx} />
          </div>
        )
      }

      if (item?.lottieJSON) {
        const mobileAnimation = JSON.parse(item.lottieJSONMobile) || null

        return (
          <EasySoftwareLottie
            animationData={JSON.parse(item.lottieJSON)} animationDataMobile={mobileAnimation} lottieImage={item.lottieImage} />
        );
      }

      if (item?.backgroundVideoFileMP4 || item?.backgroundVideoFileWEBM) {
        return (
          <EasySoftwareBackgroundVideo
          backgroundVideoFileWEBM={item.backgroundVideoFileWEBM} backgroundVideoFileMP4={item.backgroundVideoFileMP4} backgroundVideoPlaceholder={item.backgroundVideoPlaceholder} backgroundVideoLoop={item.backgroundVideoLoop} backgroundVideoAutoplay={item.backgroundVideoAutoplay} backgroundVideoControls={item.backgroundVideoControls} backgroundVideoShowOnMobile={item.backgroundVideoShowOnMobile} />
        );
      }

      return null
    })}
  </WidgetContainer>
)
export default WidgetPuxVideoContainer
