import FsLightbox from 'fslightbox-react'
import React, { FunctionComponent, useState } from 'react'
import { getImageAlt, getImageTitle } from 'Root/Shared/utils/imageTools'

export interface PuxPictureType extends MediaSelectorImageType {
  customAlt?: string
}

export interface MediaSelectorImageType {
  resizePaths: [string]
  resizePathsTablet: [string]
  resizePathsMobile: [string]
  texts: [string]
  width?: string | number
  height?: string | number
  lightbox?: boolean
  disableLazyload?: boolean
  type?: [string]
}

interface LightboxSettingsType {
  onClick?: () => void
  className?: string
}

const DEFAULT_IMG_WIDTH = 1200
const DEFAULT_IMG_HEIGHT = 1200

const PuxPicture: FunctionComponent<PuxPictureType> = (props) => {
  const [toggler, setToggler] = useState(false)
  const lightboxSettings: LightboxSettingsType = {}

  if (props.lightbox) {
    lightboxSettings.onClick = () => setToggler(!toggler)
    lightboxSettings.className = `has-lightbox`
  }

  if (props?.resizePaths?.length && props.resizePaths[0]?.length > 0) {
    return (
      <>
        <picture {...lightboxSettings}>
          <source media='(min-width:1200px)' srcSet={props.resizePaths[0]} />
          {props.resizePathsTablet?.length && (
            <source
              media='(min-width:768px)'
              srcSet={props.resizePathsTablet[0]}
            />
          )}
          {props.resizePathsMobile?.length && (
            <source
              media='(min-width:320px)'
              srcSet={props.resizePathsMobile[0]}
            />
          )}
          <img
            loading={props.disableLazyload ? `eager` : `lazy`}
            src={props.resizePaths[0]}
            title={props.texts ? getImageTitle(props.texts[0]) : ``}
            alt={
              props.texts ? getImageAlt(props.texts[0]) : props.customAlt ?? ``
            }
            width={props.width ?? DEFAULT_IMG_WIDTH}
            height={props.height ?? DEFAULT_IMG_HEIGHT}
          />
        </picture>
        {props.lightbox && (
          <FsLightbox
            toggler={toggler}
            sources={[props.resizePaths[0]]}
            type='image'
          />
        )}
      </>
    )
  } else {
    return null
  }
}

export default PuxPicture
