import './PuxLightboxGallery.scss'

import FsLightbox from 'fslightbox-react'
import React, { FunctionComponent, useState } from 'react'
import { getImageAlt, getImageTitle } from 'Root/Shared/utils/imageTools'

import { PuxMediaSelectorType } from '../content/mediaImage/PuxMediaSelector'

const PuxLightboxGallery: FunctionComponent<PuxMediaSelectorType> = (props) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0,
  })

  const data = props.mediaSelectorImage
  if (!data?.resizePaths?.length) {
    return null
  }

  return (
    <div className='LightboxGallery'>
      {data.resizePaths.map((image, idx) => (
        <picture
          className='LightboxGallery-thumb'
          key={idx}
          onClick={() => {
            setLightboxController({
              toggler: !lightboxController.toggler,
              slide: idx,
            })
          }}
        >
          <source media='(min-width:1200px)' srcSet={image} />
          <source
            media='(min-width:768px)'
            srcSet={data.resizePathsTablet[idx]}
          />
          <source
            media='(min-width:320px)'
            srcSet={data.resizePathsMobile[idx]}
          />
          <img
            loading={props.disableLazyload ? `eager` : `lazy`}
            src={image}
            title={data.texts ? getImageTitle(data.texts[0]) : ``}
            alt={data.texts ? getImageAlt(data.texts[0]) : ``}
            width={props.width}
            height={props.height}
          />
        </picture>
      ))}

      <FsLightbox
        sources={data.resizePaths}
        type='image'
        toggler={lightboxController.toggler}
        sourceIndex={lightboxController.slide}
      />
    </div>
  )
}

export default PuxLightboxGallery
