import './EasySoftwareBenefits.scss'

import React, { FunctionComponent } from 'react'
import PuxImageBox, {
  PuxImageBoxType,
} from 'Shared/components/PuxImageBox/PuxImageBox'

export interface EasySoftwareBenefitsType {
  displayText: string
  benefitsList: {
    cultureContentItems: [PuxImageBoxType]
  }
  benefitsImagePosition: string
  benefitsImageSize: string
}

// const rootClass = `benefits`

const EasySoftwareBenefits: FunctionComponent<EasySoftwareBenefitsType> = (
  props
) => {
  const classesItems: string[] = [`Benefits-items`]

  if (props.benefitsImagePosition) {
    classesItems.push(props.benefitsImagePosition)
  }
  if (props.benefitsImageSize) {
    classesItems.push(props.benefitsImageSize)
  }

  return (
    <div className='Benefits'>
      <div className={classesItems.join(` `)}>
        {props.benefitsList &&
          props.benefitsList.cultureContentItems &&
          props.benefitsList.cultureContentItems.map((item) => (
            <PuxImageBox key={item?.contentItemId} columns={4} {...item} />
          ))}
      </div>
    </div>
  )
}

export default EasySoftwareBenefits
