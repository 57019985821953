import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareTabs, {
  EasySoftwareTabsType,
} from '../EasySoftwareTabs/EasySoftwareTabs'

export interface WidgetEasySoftwareTabsType extends WidgetBase, EasySoftwareTabsType {
  contentType: 'EasySoftwareTabs'
}

const WidgetEasySoftwareTabs: FunctionComponent<WidgetEasySoftwareTabsType> = (
  props
) => (

  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
    fullWidth
  >
    <EasySoftwareTabs
      bag={props.bag}
      tabsContentPositionDesktop={props.tabsContentPositionDesktop}
      tabsBackground={props.tabsBackground}
      pageContentItemId={props.pageContentItemId}
      editorPath={props.editorPath}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareTabs
