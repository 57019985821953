import { NewsPartType } from "../components/PuxRepeater/PuxRepeaterParts/NewsPart"
import { getRepeaterQuery } from "../queries/repeater-queries"
import { puxGraphqlHelper } from "./data/puxGraphqlHelper"

interface ContentItemQueryResult {
  easySoftwareNews: NewsPartType[]
}

export const getNewsRelatedItems = async (contentItemIds: string[], limit?: number, sort?: boolean, preferedContentLinkCulture?: string): Promise<NewsPartType[]> => {
  const contentItemQuery = `
      {
          orchard {
              ${getRepeaterQuery({ contentType: "EasySoftwareNews", itemIdsArray: contentItemIds, limit: limit ?? 3, sort: sort ?? false, preferedContentLinkCulture: preferedContentLinkCulture?.toLowerCase() ?? "" })}
          }
      }
  `

  const contentItemNode = await puxGraphqlHelper<ContentItemQueryResult>(contentItemQuery, {
    errorMetadata:
    {
      message: `Error while running GraphQL query in getWidgetsFromOrchardWidgetZone for contentItemId:${contentItemIds}`,
    }
  })

  if (!contentItemNode?.easySoftwareNews) {
    throw `Error: contentItemNode.contentItem is null or undefined in getWidgetsFromOrchardWidgetZone for contentItemId:${contentItemIds}`
  }

  return contentItemNode.easySoftwareNews
}
