// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export default (context) => {
  let modalMauticFormName
  let modalMauticSubmissionFormIdentifier
  if (context.querySelector(`.mauticform_wrapper form`)) {
    modalMauticFormName = context
      .querySelector(`.mauticform_wrapper form`)
      .getAttribute(`data-mautic-form`)
  }
  if (modalMauticFormName) {
    if (
      context.querySelector(
        `#mauticform_input_` +
        modalMauticFormName +
        `_submission_form_identifier`
      )
    ) {
      modalMauticSubmissionFormIdentifier = context.querySelector(
        `#mauticform_input_` +
        modalMauticFormName +
        `_submission_form_identifier`
      ).value
    }
    const SubmissionFormIdentifierReg = new RegExp(`;(.*);`)
    const SubmissionFormIdentifierArray = SubmissionFormIdentifierReg.exec(
      modalMauticSubmissionFormIdentifier
    )
    const SubmissionFormIdentifier = SubmissionFormIdentifierArray?.length
      ? SubmissionFormIdentifierArray[1]
      : ``
    // push form opened in modal event
    window.dataLayer.push({
      event: `gaEvent`,
      gaEventData: {
        eventCategory: `Lead`,
        eventAction: SubmissionFormIdentifier,
        eventLabel: `Popup opened`,
        nonInteraction: false,
      },
      eventCallback: function () {
        window.dataLayer.push({ gaEventData: undefined })
      },
    })
    // set up a calback event for submit to mautic
    if (typeof window.MauticFormCallback == `undefined`) {
      window.MauticFormCallback = {}
    }
    // if (typeof window.MauticFormCallback[modalMauticFormName] === `undefined`) {
    if (true) {
      window.MauticFormCallback[modalMauticFormName] = {
        onValidate: function (formValid) {
          // before form submit
          // validate reCaptcha
          if (typeof window?.grecaptcha == `undefined`) {
            const head = document.getElementsByTagName(`head`)[0]
            const script = document.createElement(`script`)
            script.type = `text/javascript`
            script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaJSLibraryLoadModal&render=6Ldqq8QUAAAAAA-kn7Qr9lK1lWwZO29qmlqFhfvC`
            head.appendChild(script)
          } else {
            window?.grecaptcha
              .execute(`6Ldqq8QUAAAAAA-kn7Qr9lK1lWwZO29qmlqFhfvC`)
              .then(function (token) {
                if (
                  context.querySelector(
                    `#mauticform_input_` + modalMauticFormName + `_recaptcha`
                  )
                ) {
                  context.querySelector(
                    `#mauticform_input_` + modalMauticFormName + `_recaptcha`
                  ).value = token
                }
              })
          }
          if (document.getElementById("mauticform_input_" + modalMauticFormName + "_recaptcha")) {
            if (document.getElementById("mauticform_input_" + modalMauticFormName + "_recaptcha").value == "") {
              // console.log("no recaptcha value, try again later");
              setTimeout(function () { document.getElementById("mauticform_input_" + modalMauticFormName + "_submit")?.click(); }, 500);
              return false;
            }
          }
          return null;
        },
        onResponse: function (response) {
          let finalLocation = location.hostname
          const splittedLocation = location.hostname.split(".")

          if (splittedLocation[0].length === 2) {
            finalLocation = splittedLocation.slice(1).join(".")
          }

          // after form submit
          if (response.success) {
            let PathLang = ``
            const language = document.documentElement.lang

            if (language.toLowerCase() != `en-gb` && language.includes("-")) {
              PathLang = document.location.pathname.split(`/`)[1] + '/'
            }

            if (
              typeof window.MauticFormCallback[modalMauticFormName][
              `onResponseRun`
              ] == `undefined`
            ) {
              window.MauticFormCallback[modalMauticFormName][`onResponseRun`] = 1
              window?.dataLayer.push({
                event: `gaEvent`,
                gaEventData: {
                  eventCategory: `Lead`,
                  eventAction: SubmissionFormIdentifier,
                  eventLabel: `Submitted`,
                  nonInteraction: false,
                },
                eventCallback: function () {
                  window?.dataLayer.push({ gaEventData: undefined })
                  setTimeout(function () {

                    window.location.replace("https://" + finalLocation + "/" + PathLang + "thank-you-for-contacting-us");
                  }, 1000);
                },
              })
            }
            // redirect to thank you
            setTimeout(function () { window.location.replace("https://" + finalLocation + "/" + PathLang + "thank-you-for-contacting-us"); }, 4000);
          } else if (typeof response.validationErrors !== "undefined") {
            if (typeof response.validationErrors.recaptcha !== "undefined") {
              if (document.getElementById("mauticform_" + modalMauticFormName + "_error")) {
                document.getElementById("mauticform_" + modalMauticFormName + "_error").textContent = response.validationErrors.recaptcha;
              }
            }
          }
        }
      }
    }
    // set onsubmit attribute to prevent "double" initialization
    // set up element needed for ajax submit
    if (typeof window.MauticFormValidations != `undefined`) {
      window.MauticFormsInitiated = Object.keys(window.MauticFormValidations)
      for (let i = 0, n = window.MauticFormsInitiated.length; i < n; i++) {
        const formId = window.MauticFormsInitiated[i]
        const theForm = context.querySelector(`#mauticform_` + formId)
        if (theForm) {
          theForm.setAttribute(
            `onsubmit`,
            `event.preventDefault(); MauticSDK.validateForm('` +
            formId +
            `', true);`
          )
          theForm.target = `mauticiframe_` + formId
          if (!document.querySelector(`#mauticform_` + formId + `_messenger`)) {
            const messengerInput = document.createElement(`INPUT`)
            messengerInput.type = `hidden`
            messengerInput.setAttribute(`name`, `mauticform[messenger]`)
            messengerInput.setAttribute(
              `id`,
              `mauticform_` + formId + `_messenger`
            )
            messengerInput.value = 1
            theForm.appendChild(messengerInput)
          }
        }
      }
    }
    // init Mautic Library for this form (actually all forms) and prevent "double" callback initialization
    // MauticSDK.onLoad();
    if (typeof window.MauticFormInit == `undefined`) {
      window.MauticFormInit = {}
    }
    if (typeof window.MauticFormInit[modalMauticFormName] == `undefined`) {
      window.MauticFormCallbackTemp = window.MauticFormCallback
      window.MauticFormCallback = {}
      window.MauticSDK?.onLoad()
      window.MauticFormCallback = window.MauticFormCallbackTemp
      window.MauticFormInit[modalMauticFormName] = 1
    }
  }
}
