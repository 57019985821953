import React, { FunctionComponent } from 'react'
import { isSameLocale } from './localeURL'
import { Link } from 'gatsby'
import { useEditorStatus } from './edit/useEditorStatus'
import { usePreviewStatus } from './edit/usePreviewStatus'

export interface PuxLinkProps {
  className?: string
  /** A class to apply when this Link is active */
  activeClassName?: string
  /** Inline styles for when this Link is active */
  activeStyle?: object
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  /** Class the link as highlighted if there is a partial match via a the `to` being prefixed to the current url */
  partiallyActive?: boolean
  /** Used to declare that this link replaces the current URL in history with the target */
  replace?: boolean
  target?: '_blank'
  /** The URL you want to link to */
  to: string
}

const PuxLink: FunctionComponent<PuxLinkProps> = (props) => {
  const isEditor = useEditorStatus()
  const isPreview = usePreviewStatus()

  if (isPreview) {
    const prefixUrl = process.env.GATSBY_WEBSITE_URL ?? ""

    const newProps = { ...props, to: "/preview" + props.to.replace(prefixUrl, "") }

    return (
      <a
        className={newProps.className}
        onClick={newProps.onClick}
        href={newProps.to}
        target={newProps.target}>{newProps.children}</a>
    )
  }

  if (isEditor) {
    const prefixUrl = process.env.GATSBY_WEBSITE_URL ?? ""

    const newProps = { ...props, to: "/edit" + props.to.replace(prefixUrl, "") }

    return (
      <a
        className={newProps.className}
        onClick={newProps.onClick}
        href={newProps.to}
        target={newProps.target}>{newProps.children}</a>
    )
  }

  if (isSameLocale(props.to)) {
    return <Link {...props}>{props.children}</Link>
  }

  return (
    <a
      className={props.className}
      onClick={props.onClick}
      href={props.to}
      target={props.target}>{props.children}</a>
  )
}

export default PuxLink