class DynamicRS {
  file: PO_JSONType | undefined
  resStrings: translationsItemType | undefined

  constructor() {
    /* eslint-disable */
    (async () => {
      const file = await import(`@localizations`)

      this.file = file.default
      this.resStrings = this.file?.translations['']
    })();
  }

  getResourceString(key: string): string {
    if (!this.resStrings) {
      return key
    }

    if (!this.resStrings[key]) {
      return key
    }

    if (this.resStrings[key]?.msgstr.length < 1) {
      return key
    }

    const translation = this.resStrings[key]?.msgstr[0]

    if (translation) {
      return translation
    } else {
      return key
    }
  }
}

interface PO_JSONType {
  charset: string
  headers: headersType
  translations: translationsType
}

interface translationsType {
  [key: string]: translationsItemType
}

interface translationsItemType {
  [key: string]: {
    msgid: string
    msgstr: [string]
  }
}

interface headersType {
  'content-transfer-encoding': string
  'content-type': string
  language: string
  'mime-version': string
  'plural-forms': string
}

let dynamicRSinstance: DynamicRS | null = null

export function initDynamicRS(): void {
  dynamicRSinstance = new DynamicRS()
}

export function dt(key: string): string {
  if (dynamicRSinstance !== null) {
    return dynamicRSinstance.getResourceString(key)
  } else {
    return key
  }
}
