/* eslint-disable prettier/prettier */
import React, { FunctionComponent } from 'react'
import { Collapse } from 'react-collapse'
import { PuxMediaSelectorType } from '../content/mediaImage/PuxMediaSelector'
import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import { PuxVideoType } from '../PuxVideo/PuxVideo'
import { MediaSelectorImageType } from '../content/mediaImage/PuxPicture'
import EasySoftwareAccordionItemContent from './EasySoftwareAccordionItemContent'
import EasySoftwareEditorContainer from '../EasySoftwareEditorContainer/EasySoftwareEditorContainer'

export interface EasySoftwareAccordionItemType {
  contentType?: string,
  contentItemId?: string,
  displayText: string,
  accordionItemContent: {
    html: string
  }
  puxButton: PuxButtonType,
  accordionItemMedia: {
    cultureContentItems: {
      displayText
      contentType
      backgroundVideoFileWEBM: {
        paths: [string],
        type: [string]
      },
      backgroundVideoFileMP4: {
        paths: [string],
        type: [string]
      },
      backgroundVideoPlaceholder: {
        paths: [string]
      },
      lottieJSON: string,
      lottieJSONMobile: string | null,
      lottieImage: MediaSelectorImageType | null,
      backgroundVideoLoop: boolean,
      backgroundVideoAutoplay: boolean,
      backgroundVideoControls: boolean,
      backgroundVideoShowOnMobile: boolean,
      puxVideo: PuxVideoType
    }
  }
  puxMediaSelector: PuxMediaSelectorType
}

export interface EasySoftwareAccordionItemProps {
  pageContentItemId: string,
  editorPath: string
  data: EasySoftwareAccordionItemType,
  index: number,
  isOpen: boolean,
  accordionContentPosition: boolean,
  accordionContentLocation: boolean,
  setAccordionOpen: (index) => void
}

const EasySoftwareAccordionItem: FunctionComponent<EasySoftwareAccordionItemProps> = (
  props
) => {
  return (
    <div className={`Accordion-item ${props.isOpen ? `active` : ``}`}>
      <div className="Accordion-heading" onClick={() => props.setAccordionOpen(props.index)}>
        <EasySoftwareEditorContainer
          type="input"
          content={props.data.displayText}
          wysiwygId={props.data.contentItemId + `-heading`}
          editorPath={props.editorPath + `,cid-${props.data.contentItemId},displayText`}
          pageContentItemId={props.pageContentItemId}
        />
      </div>
      <Collapse isOpened={props.isOpen}>
        <div className="Accordion-content">

          {props.accordionContentLocation && !props.accordionContentPosition &&
            <EasySoftwareAccordionItemContent data={props.data} index={props.index} isOpen={props.isOpen} setAccordionOpen={props.setAccordionOpen} />
          }

          <EasySoftwareEditorContainer className="Accordion-text" content={props.data.accordionItemContent} wysiwygId={props.data.contentItemId + `-content`} pageContentItemId={props.pageContentItemId} editorPath={props.editorPath + `,cid-${props.data.contentItemId},easySoftwareAccordionItem,accordionItemContent,html`} />

          {props.accordionContentLocation && props.accordionContentPosition &&
            <EasySoftwareAccordionItemContent data={props.data} index={props.index} isOpen={props.isOpen} setAccordionOpen={props.setAccordionOpen} />
          }

          <PuxButton
            buttonType={props.data.puxButton.buttonType}
            buttonSize={props.data.puxButton.buttonSize}
            buttonColor={props.data.puxButton.buttonColor}
            buttonLink={{
              url: props.data.puxButton.buttonLink.url,
              internal: props.data.puxButton.buttonLink.internal,
              text: props.data.puxButton.buttonLink.text,
            }}
          />
        </div>
      </Collapse>
    </div>
  )
}

export default EasySoftwareAccordionItem
