import { MediaSelectorImageType } from "../components/content/mediaImage/PuxPicture"

export interface AnchorItemType {
  displayText: string
  contentType: 'EasySoftwareAnchorItem'
  anchorItemTarget: string
}

export interface LinkItemType {
  displayText: string
  contentType: 'EasySoftwareLinkItem'
  linkItemTarget: {
    internal: boolean[]
    text: string[]
    url: string[]
  }
}

export interface MenuItem {
  contentType: string
  render: string
  contentMenuItemDuplicateAlias?: string
  contentMenuItemDuplicateMainItem?: boolean
  contentMenuItemDuplicateHide?: boolean
  contentMenuItemDescription?: string
  contentMenuItemArrow?: boolean
  contentMenuItemMediaField?: MediaSelectorImageType
  linkMenuItemDescription?: boolean
  linkMenuItemArrow?: boolean
  linkMenuItemMediaField?: MediaSelectorImageType
  linkMenuItem?: {
    name: string
    url: string
  }
}

export interface MenuCategory {
  categoryItemDescription: string
  categoryItemName: string
  categoryItemColumns: string
  contentType: string
  menuItemsList: {
    menuItems: [MenuItem | MenuCategory]
  }
}

export interface TopMenuItem {
  render: string
  menuItemsList: {
    menuItems: [MenuCategory]
  } | null
  contentMenuItemCTA: {
    html: string
  }
  contentMenuItemDuplicateAlias: string | null
}

interface RenderItem {
  url: string
  name: string
  internal?: boolean
}

export const menuItemsMapper = (
  landingPageItems: (AnchorItemType | LinkItemType)[]
): TopMenuItem[] => {
  const mappedItems: TopMenuItem[] = []
  landingPageItems.map((landingPageItem) => {
    let renderItem: RenderItem | null = null

    if (landingPageItem.contentType === `EasySoftwareAnchorItem`) {
      renderItem = anchorItemMapper(landingPageItem)
    }

    if (landingPageItem.contentType === `EasySoftwareLinkItem`) {
      renderItem = linkItemMapper(landingPageItem)
    }

    if (renderItem) {
      const mappedItem: TopMenuItem = {
        render: JSON.stringify(renderItem),
        contentMenuItemCTA: { html: `` },
        contentMenuItemDuplicateAlias: null,
        menuItemsList: null,
      }

      return mappedItems.push(mappedItem)
    }

    return
  })

  return mappedItems
}

const anchorItemMapper = (anchorItem: AnchorItemType): RenderItem => {
  const renderItem: RenderItem = {
    url: `#${anchorItem.anchorItemTarget}`,
    name: anchorItem.displayText,
  }

  return renderItem
}

const linkItemMapper = (linkItem: LinkItemType): RenderItem => {
  const renderItem: RenderItem = {
    url: linkItem.linkItemTarget?.url[0] ?? ``,
    name: linkItem.linkItemTarget?.text[0] ?? ``,
    internal: linkItem.linkItemTarget?.internal[0] ?? true,
  }

  return renderItem
}
