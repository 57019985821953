import { graphql, useStaticQuery } from 'gatsby'
import { getLocalizedData } from 'Shared/utils/localeURL'
const localizedConfigData = getLocalizedData(
  process.env.LOCALE as string,
  process.env.PROJECT as string
)

export const useFooterData = () => {
  const {
    orchard: { menu },
  } = useStaticQuery(
    graphql`
      {
        orchard {
          menu(where: { alias: { alias_ends_with: "/footer-navigation" } }) {
            displayText
            alias {
              alias
            }
            menuItemsList {
              menuItems {
                ... on Orchard_PuxMegamenuCategoryItem {
                  categoryItemName
                  menuItemsList {
                    menuItems {
                      ... on Orchard_ContentMenuItem {
                        contentType
                        render
                        displayText
                      }
                      ... on Orchard_LinkMenuItem {
                        contentType
                        linkMenuItem {
                          name
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const languageMenu = menu?.filter(
    (m) => m.alias.alias.indexOf(localizedConfigData.isoCode) === 0
  )

  return languageMenu && languageMenu.length
    ? languageMenu[0].menuItemsList
    : menu[0].menuItemsList
}
