import './EasySoftwareNavigator.scss'

import React, { FunctionComponent } from 'react'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

interface NavigatorSubcategoryType {
  displayText: string
  navigatorSubcategoryText: PuxWysiwygHtmlBodyType
  contentItemId?: string
}
interface NavigatorCategoryType {
  navigatorCategorySubtitle: string
  displayText: string
  navigatorSubcategories: {
    contentItems: [NavigatorSubcategoryType]
  }
  contentItemId?: string
}
export interface EasySoftwareNavigatorType {
  contentItemId?: string
  displayText: string
  navigatorCategories: {
    contentItems: [NavigatorCategoryType]
  }
  pageContentItemId: string
  editorPath: string
}

const EasySoftwareNavigator: FunctionComponent<EasySoftwareNavigatorType> = (
  props
) => {
  return (
    <div className="Navigator">
      <h2 className="Navigator-title">{props.displayText}</h2>
      <div className="Navigator-categories">
        {props.navigatorCategories &&
          props.navigatorCategories.contentItems &&
          props.navigatorCategories.contentItems.map((cat, catIdx) => (
            <div className="Navigator-category" key={catIdx}>
              <h3 className="Navigator-category-title">
                {cat.displayText}
                <span>{cat.navigatorCategorySubtitle}</span>
              </h3>
              <div className="Navigator-subcategories">
                {cat.navigatorSubcategories &&
                  cat.navigatorSubcategories.contentItems &&
                  cat.navigatorSubcategories.contentItems.map(
                    (subcat, subcatIdx) => (
                      <div
                        className="Navigator-subcategory"
                        key={subcatIdx}
                      >
                        <h4 className="Navigator-subcategory-title">
                          {subcat.displayText}
                        </h4>
                        <div className="Navigator-subcategory-content">
                          <EasySoftwareEditorContainer
                            pageContentItemId={props.pageContentItemId}
                            inline
                            content={subcat.navigatorSubcategoryText}
                            editorPath={props.editorPath + `,cid-${cat.contentItemId},navigatorSubcategories,contentItems,cid-${subcat.contentItemId},easySoftwareNavigatorSubcategory,navigatorSubcategoryText,html`}
                            wysiwygId={props.contentItemId}
                          />
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default EasySoftwareNavigator
