import { useLocation } from '@reach/router'
import FsLightbox from 'fslightbox-react'
import React, { FunctionComponent, useState } from 'react'
import { PuxAnchorLink } from 'Root/Shared/utils/PuxAnchorLink/PuxAnchorLink'
import { getModalTriggerId } from 'Shared/components/PuxModal/PuxModal'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import PuxLink from 'Shared/utils/PuxLink'

import { getYTSubtitlesLocale } from '../PuxVideo/PuxVideo'

export type PuxButtonStyle =
  | `Button--primary`
  | `Button--secondary`
  | `Button--link`
export type PuxButtonSize = `Button--large` | `Button--medium` | `Button--small`
export type PuxButtonColor = `Button--yellow` | `Button--blue` | `Button--white`

export interface PuxButtonType {
  buttonType: PuxButtonStyle
  buttonSize: PuxButtonSize
  buttonColor: PuxButtonColor
  buttonDisabled?: boolean
  buttonNewWindow?: boolean
  buttonOpenModalId?: string
  buttonLink: {
    url: [string]
    internal: [boolean]
    text: [string]
  }
}

const PuxButton: FunctionComponent<PuxButtonType> = (props) => {
  const target = props.buttonNewWindow ? `_blank` : undefined
  const url = props.buttonLink?.url?.length && props.buttonLink.url[0]
  const text = props.buttonLink?.text?.length && props.buttonLink.text[0]
  const isInternal = props.buttonLink?.internal && props.buttonLink.internal[0]
  const classes: string[] = [`Button`]
  const [videoToggler, setVideoToggler] = useState(false)

  if (props.buttonType) {
    classes.push(props.buttonType)
  }

  if (props.buttonSize) {
    classes.push(props.buttonSize)
  }

  if (props.buttonColor) {
    classes.push(props.buttonColor)
  }

  if (props.buttonDisabled) {
    classes.push(`is-disabled`)
  }

  const openModal = (event) => {
    event.preventDefault()
    const trigger = document.getElementById(
      getModalTriggerId(props.buttonOpenModalId)
    )
    if (trigger) {
      trigger.click()
    } else {
      console.log(
        `Modal error: Section-modal with ID "${props.buttonOpenModalId}" not found`
      )
    }
  }

  if (!url && !text) return null

  if (url && isInternal) {
    return (
      <PuxLink
        className={classes.join(` `)}
        to={`${getLocalizedUrl(url)}`}
        target={target}
        onClick={props.buttonOpenModalId ? openModal : undefined}
        data-open-modal-id={props.buttonOpenModalId}
      >
        {text}
      </PuxLink>
    )
  }

  if (url && !isInternal && url.toLocaleLowerCase().startsWith(`yt:`)) {
    const removeYTPrefix = (string: string | [string]) => {
      const inputString = Array.isArray(string) ? string[0] : string

      return inputString.replace(/^YT:/, ``).replace(/^yt:/, ``)
    }
    const yTLocale = getYTSubtitlesLocale()
    const videoID = removeYTPrefix(url)
    const videoSources: JSX.Element[] = []
    videoSources.push(
      <iframe
        loading='lazy'
        style={{
          width: `90vw`,
          height: `${(90 / 16) * 9}vw`,
        }}
        src={`https://www.youtube-nocookie.com/embed/${videoID}?autoplay=1&modestbranding=1&rel=0&iv_load_policy=3&cc_load_policy=1&cc_lang_pref=${yTLocale}`}
        frameBorder='0'
        allow='autoplay; encrypted-media;'
      ></iframe>
    )

    return (
      <>
        <button
          type={`button`}
          className={classes.join(` `)}
          onClick={() => setVideoToggler(!videoToggler)}
        >
          {text}
        </button>
        <FsLightbox
          toggler={videoToggler}
          sources={(videoSources as unknown) as string[]}
        />
      </>
    )
  }

  if (url && !isInternal && !url.includes(`#`)) {
    return (
      <a
        className={classes.join(` `)}
        href={url}
        target={target}
        rel={target === `_blank` ? `noopener` : undefined}
        onClick={props.buttonOpenModalId ? openModal : undefined}
        data-open-modal-id={props.buttonOpenModalId}
      >
        {text}
      </a>
    )
  }

  if (url && !isInternal && url.includes(`#`)) {
    const location = useLocation()
    const pathname = location.pathname
    const anchorPath = pathname + url

    return (
      <PuxAnchorLink
        className={classes.join(` `)}
        to={anchorPath.replace(`//#`, `/#`)}
        data-open-modal-id={props.buttonOpenModalId}
      >
        {text}
      </PuxAnchorLink>
    )
  }

  return (
    <button
      type={`button`}
      className={classes.join(` `)}
      onClick={props.buttonOpenModalId ? openModal : undefined}
      data-open-modal-id={props.buttonOpenModalId}
    >
      {text}
    </button>
  )
}

export default PuxButton
