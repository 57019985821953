import MauticTrialForm from '@componentsfld/MauticForms/MauticTrialForm'
import React, { FunctionComponent } from 'react'

import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareSideCardNewsletterType {
  contentType: 'EasySoftwareSideCardNewsletter'
  displayText: string
  contentItemId: string
  sideCardNewsletterText: PuxWysiwygHtmlBodyType
  widgetOrder?: number
}

import './EasySoftwareSideCard.scss'

const rootClass = `sidebar`

const EasySoftwareSideCardNewsletter: FunctionComponent<EasySoftwareSideCardNewsletterType> = (
  props
) => {
  return (
    <div className='Sidebar-box Sidebar-box--newsletter '>
      <h3 className='Sidebar-box-title'>{props.displayText}</h3>
      <PuxWysiwyg
        content={props.sideCardNewsletterText}
        className='Sidebar-box-perex'
      />
      <MauticTrialForm />
    </div>
  )
}

export default EasySoftwareSideCardNewsletter
