import React, { FunctionComponent } from 'react'

import FormBuilder, {
  FormBuilderProps,
  PuxFormType,
} from '../builders/FormBuilder'
import WidgetContainer from '../builders/WidgetContainer'

const WidgetPuxFormWrapper: FunctionComponent<PuxFormType> = (props) => {
  const newProps = {
    formBuilderData: props,
  } as FormBuilderProps
  return (
    <>
      <WidgetContainer
        {...props?.puxWidgetProperties}
        {...props?.puxWidgetAnimation}
        {...props?.metadata}
        fullWidth={true}
        customClassName='WidgetContainer--form'
      >
        <FormBuilder {...newProps} />
      </WidgetContainer>
    </>
  )
}

export default WidgetPuxFormWrapper
