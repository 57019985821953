import React, { FunctionComponent } from 'react'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import EasySoftwareBackgroundVideo, { MediaFile, MediaVideoFile } from '../content/mediaBackgroundVideo/EasySoftwareBackgroundVideo'
import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import { MediaSelectorImageType } from '../content/mediaImage/PuxPicture'
import EasySoftwareLottie from '../content/mediaAnimation/EasySoftwareLottie'

export interface EasySoftwareHeroBannerItemAnimationType {
  contentItemId: string
  contentType: string
  displayText: string
  heroBannerItemAnimationContent: PuxWysiwygHtmlBodyType
  heroBannerItemAnimationButton1: PuxButtonType
  heroBannerItemAnimationButton2: PuxButtonType
  easySoftwareHeroBannerPosition: {
    heroBannerPositionMobile: string
    heroBannerPositionDesktop: string
    heroBannerPositionMobileCenterContent: boolean
  }
  heroBannerItemAnimationLottie: {
    contentItems: {
      displayText: string,
      lottieJSON: string,
      lottieJSONMobile: string | null,
      lottieImage: MediaSelectorImageType | null,
    }
  },
  heroBannerItemAnimationWEBM: MediaVideoFile,
  heroBannerItemAnimationMP4: MediaVideoFile,
  heroBannerItemAnimationPlaceholder: MediaFile,
  heroBannerItemAnimationLoop: boolean,
  heroBannerItemAnimationAutoplay: boolean,
  heroBannerItemAnimationControls: boolean,
  heroBannerItemAnimationShowOnMobile: boolean
}

export interface EasySoftwareHeroBannerItemAnimationProps {
  data: EasySoftwareHeroBannerItemAnimationType
  pageContentItemId: string
  editorPath: string
}

export type HeroBannerPositionDesktop = `HeroBannerItem--desktopReversed`
export type HeroBannerPositionMobile = `HeroBannerItem--mobileReversed`

const EasySoftwareHeroBannerItemAnimation: FunctionComponent<EasySoftwareHeroBannerItemAnimationProps> = (
  props
) => {

  const primaryButton = props.data.heroBannerItemAnimationButton1,
    secondaryButton = props.data.heroBannerItemAnimationButton2,
    classes = [`HeroBannerItem`]

  if (props.data.easySoftwareHeroBannerPosition.heroBannerPositionMobile) {
    classes.push(
      props.data.easySoftwareHeroBannerPosition.heroBannerPositionMobile
    )
  }
  if (props.data.easySoftwareHeroBannerPosition.heroBannerPositionDesktop) {
    classes.push(
      props.data.easySoftwareHeroBannerPosition.heroBannerPositionDesktop
    )
  }
  if (
    props.data.easySoftwareHeroBannerPosition
      .heroBannerPositionMobileCenterContent
  ) {
    classes.push(`HeroBannerItem--mobileCenterContent`)
  }

  const lottieAnimation = props.data.heroBannerItemAnimationLottie

  return (
    <div className={classes.join(` `)}>
      <div className='HeroBannerItem-animation'>
        {lottieAnimation?.contentItems[0]?.lottieJSON ? (
          <EasySoftwareLottie
            lottieImage={lottieAnimation?.contentItems[0]?.lottieImage}
            animationData={JSON.parse(lottieAnimation?.contentItems[0]?.lottieJSON)}
            animationDataMobile={lottieAnimation?.contentItems[0]?.lottieJSONMobile ? JSON.parse(lottieAnimation?.contentItems[0]?.lottieJSONMobile) : null} />
        ) : (
          <EasySoftwareBackgroundVideo
            backgroundVideoFileWEBM={props.data.heroBannerItemAnimationWEBM} backgroundVideoFileMP4={props.data.heroBannerItemAnimationMP4} backgroundVideoPlaceholder={props.data.heroBannerItemAnimationPlaceholder} backgroundVideoLoop={props.data.heroBannerItemAnimationLoop} backgroundVideoAutoplay={props.data.heroBannerItemAnimationAutoplay} backgroundVideoControls={props.data.heroBannerItemAnimationControls} backgroundVideoShowOnMobile={props.data.heroBannerItemAnimationShowOnMobile} />
        )}
      </div>
      <div className='HeroBannerItem-text'>
        <EasySoftwareEditorContainer pageContentItemId={props.pageContentItemId} editorPath={props.editorPath} content={props.data.heroBannerItemAnimationContent} wysiwygId={props.data.contentItemId} />
        <div className='HeroBannerItem-buttons'>
          {primaryButton?.buttonLink.text && <PuxButton {...primaryButton} />}
          {secondaryButton?.buttonLink.text && (
            <PuxButton {...secondaryButton} />
          )}
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareHeroBannerItemAnimation
