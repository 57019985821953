import React from 'react'
import { MultipleFieldErrors } from 'react-hook-form';

interface IFormErrorHelper {
    message: string;
    messages?: MultipleFieldErrors | undefined;
}

const FormErrorHelper: React.FC<IFormErrorHelper> = (props) => {
    return (
        <div className='FormError'>
            {props.message}
        </div>
    )
}

export default FormErrorHelper