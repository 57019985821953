import axios, { AxiosResponse } from 'axios'
const USERNAME = process.env.CHANGE_LOG_USERNAME || ``
const PASSWORD = process.env.CHANGE_LOG_PASSWORD || ``
const ENDPOINT = process.env.CHANGE_LOG_ENDPOINT
const QUERY_ID = process.env.CHANGE_LOG_QUERY_ID
const BUILD_CUSTOM_FIELD_NAME = `Platform Version`
const DESCRIPTION_CUSTOM_FIELD_NAME = `Description for changelog`

export interface ChangeLogType {
  version: string
  type: string
  subject: string
  build: string
  status: string
  description: string
}

export interface ChangeLogDataType {
  versions?: string[]
  data?: ChangeLogType[]
}

export interface IssueResponseType {
  issues: IssueType[]
  total_count: number
  offset: number
  limit: number
}

export interface IssueType {
  id: number
  fixed_version: IssueGeneralItemType
  subject: string
  status: IssueGeneralItemType
  tracker: IssueGeneralItemType
  custom_fields: IssueCustomFieldType[]
}

interface IssueGeneralItemType {
  id: number
  name: string
}

interface IssueCustomFieldType extends IssueGeneralItemType {
  internal_name: string
  field_format: string
  value: string
}

const getChangeLogData = async (): Promise<ChangeLogDataType> => {
  const changeLogData: ChangeLogDataType = {}
  const changeLogVersions: string[] = []
  const fetchedIssues = await fetchIssuesFromEndpoint()
  const mappedData = mapIssuesToChangeLogData(fetchedIssues)
  mappedData.map((item) => changeLogVersions.push(item.version))

  changeLogData.data = mappedData
  changeLogData.versions = [...new Set(changeLogVersions)]

  return changeLogData
}

const fetchIssuesFromEndpoint = async (): Promise<IssueType[]> => {
  const issuesArray: IssueType[] = []
  let queryOffset = 0
  let totalCount = 0

  if (USERNAME === `` && PASSWORD === ``) {
    throw `Empty CHANGE_LOG_USERNAME or CHANGE_LOG_PASSWORD`
  }

  do {
    const res: AxiosResponse<IssueResponseType> = await axios
      .get<IssueResponseType>(
        `${ENDPOINT}?query_id=${QUERY_ID}&limit=100&offset=${queryOffset}`,
        {
          auth: {
            username: USERNAME,
            password: PASSWORD,
          },
        }
      )
      .then((response) => response)
      .catch((error) => error)

    if (res && res.data) {
      issuesArray.push(...res.data.issues)
      queryOffset = queryOffset + 100
      totalCount = res.data.total_count
    } else {
      break
    }
  } while (queryOffset < totalCount)

  const sortedIssuesByReleaseDate = issuesArray.reverse()

  return sortedIssuesByReleaseDate
}

const getCustomFieldValue = (
  issue: IssueType,
  customFieldName: string
): string => {
  if (issue.custom_fields === undefined) {
    return ``
  }

  const customField = issue.custom_fields.filter(
    (field) => field.name === customFieldName
  )

  return customField.length ? customField[0].value : ``
}

const mapIssuesToChangeLogData = (issues: IssueType[]): ChangeLogType[] => {
  const changeLogData: ChangeLogType[] = []

  issues.map((issue) => {
    const changeLog: ChangeLogType = {
      version: issue.fixed_version.name,
      type: issue.tracker.name,
      subject: issue.subject,
      build: getCustomFieldValue(issue, BUILD_CUSTOM_FIELD_NAME),
      status: issue.status.name,
      description: getCustomFieldValue(issue, DESCRIPTION_CUSTOM_FIELD_NAME),
    }

    changeLogData.push(changeLog)
  })

  return changeLogData
}

export default getChangeLogData
