import './PartnerPart.scss'

import { Modal } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Shared/components/content/mediaImage/PuxMediaSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { dt } from 'Shared/utils/dynamicRS'

export interface PartnerPartType {
  contentType: 'EasySoftwarePartner'
  contentItemId: string
  displayText: string
  createdUtc: string
  partnerCountry: string
  partnerLevel: string
  partnerIndustry: string[]
  partnerRegion: string[]
  partnerLogo: PuxMediaSelectorType
  partnerText: PuxWysiwygHtmlBodyType
  partnerContact: PuxWysiwygHtmlBodyType
  partnerWebsiteUrl: {
    text: string
    url: string
  }
}

const PartnerPart: FunctionComponent<PartnerPartType> = (props) => {
  let icon: JSX.Element | undefined
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  switch (props.partnerLevel) {
    case `gold`:
      icon = (
        <svg
          width='21'
          height='21'
          viewBox='0 0 21 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10.375 5.66937L11.7269 8.40875L14.75 8.84812L12.5625 10.98L13.0788 13.9912L10.375 12.5694L7.67125 13.9912L8.1875 10.98L6 8.84812L9.02312 8.40875L10.375 5.66937Z'
            stroke='#EBA00F'
            stroke-width='1.25'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M10.375 19.6707C15.5527 19.6707 19.75 15.4734 19.75 10.2957C19.75 5.11805 15.5527 0.920715 10.375 0.920715C5.19733 0.920715 1 5.11805 1 10.2957C1 15.4734 5.19733 19.6707 10.375 19.6707Z'
            stroke='#EBA00F'
            stroke-width='1.25'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      )
      break
    case `silver`:
      icon = (
        <svg
          width='20'
          height='21'
          viewBox='0 0 20 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clip-path='url(#clip0_1_464)'>
            <path
              d='M10 5.66876L11.3519 8.40814L14.375 8.84751L12.1875 10.9794L12.7038 13.9906L10 12.5688L7.29625 13.9906L7.8125 10.9794L5.625 8.84751L8.64812 8.40814L10 5.66876Z'
              stroke='#0D65F2'
              stroke-width='1.25'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M10 19.6707C15.1777 19.6707 19.375 15.4734 19.375 10.2957C19.375 5.11805 15.1777 0.920715 10 0.920715C4.82233 0.920715 0.625 5.11805 0.625 10.2957C0.625 15.4734 4.82233 19.6707 10 19.6707Z'
              stroke='#0D65F2'
              stroke-width='1.25'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </g>
          <defs>
            <clipPath id='clip0_1_464'>
              <rect
                width='20'
                height='20'
                fill='white'
                transform='translate(0 0.295715)'
              />
            </clipPath>
          </defs>
        </svg>
      )
      break
    case `distributor`:
      icon = (
        <svg
          width='24'
          height='22'
          viewBox='0 0 24 22'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.728 3.74696L12.4257 5.16068L13.9858 5.38743L12.8569 6.48763L13.1233 8.04165L11.728 7.30786L10.3327 8.04165L10.5991 6.48763L9.4702 5.38743L11.0303 5.16068L11.728 3.74696Z'
            stroke='#1D83F9'
            stroke-width='1.14446'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M11.7333 10.9723C14.4053 10.9723 16.5714 8.80618 16.5714 6.13413C16.5714 3.46208 14.4053 1.29596 11.7333 1.29596C9.06121 1.29596 6.89509 3.46208 6.89509 6.13413C6.89509 8.80618 9.06121 10.9723 11.7333 10.9723Z'
            stroke='#1D83F9'
            stroke-width='1.14446'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M5.83293 14.0682L6.5306 15.482L8.09074 15.7087L6.96184 16.8089L7.22826 18.3629L5.83293 17.6291L4.4376 18.3629L4.70402 16.8089L3.57512 15.7087L5.13527 15.482L5.83293 14.0682Z'
            stroke='#114FEE'
            stroke-width='1.14446'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M5.83818 21.2955C8.51023 21.2955 10.6763 19.1294 10.6763 16.4573C10.6763 13.7853 8.51023 11.6192 5.83818 11.6192C3.16613 11.6192 1.00001 13.7853 1.00001 16.4573C1.00001 19.1294 3.16613 21.2955 5.83818 21.2955Z'
            stroke='#114FEE'
            stroke-width='1.14446'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M17.9967 14.0702L18.6943 15.4839L20.2545 15.7106L19.1256 16.8108L19.392 18.3649L17.9967 17.6311L16.6013 18.3649L16.8678 16.8108L15.7389 15.7106L17.299 15.4839L17.9967 14.0702Z'
            stroke='#1D33D3'
            stroke-width='1.14446'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M18.0019 21.2955C20.674 21.2955 22.8401 19.1294 22.8401 16.4573C22.8401 13.7853 20.674 11.6192 18.0019 11.6192C15.3299 11.6192 13.1637 13.7853 13.1637 16.4573C13.1637 19.1294 15.3299 21.2955 18.0019 21.2955Z'
            stroke='#1D33D3'
            stroke-width='1.14446'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      )
      break
    case `registered`:
      icon = (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='21'
          fill='none'
        >
          <g
            stroke='#041E49'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.25'
            clip-path='url(#a)'
          >
            <path d='M10 19.67A9.375 9.375 0 1 0 10 .92a9.375 9.375 0 1 0 0 18.75Z' />
            <path d='M15 7.295 9.436 12.86 6.5 9.923' />
          </g>
          <defs>
            <clipPath id='a'>
              <path fill='#fff' d='M0 .296h20v20H0z' />
            </clipPath>
          </defs>
        </svg>
      )
      break
  }

  return (
    <>
      <div className='pux-repeater-item PartnerPartwrapper'>
        <div className='PartnerPart-item'>
          <a
            className='PartnerPart-image'
            target={`_blank`}
            rel='noreferrer'
            href={props.partnerWebsiteUrl.url}
          >
            <PuxMediaSelector {...props.partnerLogo} height={75} width={364} />
          </a>

          <h5 className='PartnerPart-heading'>
            {/* <button type='button' onClick={handleOpen}> */}
              {props.displayText}
            {/* </button> */}
          </h5>
          <div className='PartnerPart-content'>
            <div className='PartnerPart-level'>
              {icon}
              {` ${
                props.partnerLevel.charAt(0).toUpperCase() +
                props.partnerLevel.slice(1)
              }`}
            </div>
            <div className='PartnerPart-country'>
              {dt(props.partnerCountry)}
            </div>
          </div>

          <div className='PartnerPart-perex'>
            <PuxWysiwyg
              content={props.partnerText}
              inline
              wysiwygId={props.contentItemId}
            />
          </div>
          {/* <div className='PartnerPart-readMore'>
            <button type='button' onClick={handleOpen}>
              {dt(`EasyRedmine.PartnerPart.ReadMore`)}
            </button>
          </div> */}
        </div>
      </div>

      {/* <Modal open={open} onClose={handleClose} className='PartnerModal'>
        <div className='PartnerModal-wrapper'>
          <div className='PartnerModal-content'>
            <div className='PartnerModal-details'>
              <div className='PartnerModal-image'>
                <PuxMediaSelector
                  {...props.partnerLogo}
                  height={45}
                  width={145}
                />
              </div>
              <h3>{props.displayText}</h3>
              <h4>{dt(`EasyRedmine.Modal.About.Headline`)}</h4>
              <div className='PartnerModal-about'>
                <PuxWysiwyg
                  content={props.partnerText}
                  inline
                  wysiwygId={props.contentItemId}
                />
              </div>
              <h4>{dt(`EasyRedmine.Modal.Contact.Headline`)}</h4>
              <a
                href={props.partnerWebsiteUrl.url}
                target='_blank'
                rel='noreferrer'
                className='PartnerModal-link'
              >
                {props.partnerWebsiteUrl.url}
              </a>
              <div className='PartnerModal-contact'>
                <PuxWysiwyg
                  content={props.partnerContact}
                  inline
                  wysiwygId={props.contentItemId}
                />
              </div>
            </div>

            <div className='PartnerModal-overview'>
              <div className='PartnerModal-level'>
                {icon}
                {` ${
                  props.partnerLevel.charAt(0).toUpperCase() +
                  props.partnerLevel.slice(1)
                }`}
              </div>
              {props.partnerRegion && (
                <ul className='PartnerModal-regions'>
                  {props.partnerRegion?.map((item, idx) => {
                    return <li key={idx}>{dt(item)}</li>
                  })}
                </ul>
              )}
              {props.partnerIndustry && (
                <>
                  <h4>{dt(`EasyRedmine.Modal.Industry.Headline`)}</h4>
                  <ul className='PartnerModal-industries'>
                    {props.partnerIndustry?.map((item, idx) => {
                      return <li key={idx}>{dt(item)}</li>
                    })}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  )
}

export default PartnerPart
