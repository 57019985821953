import './ChangeLogPage.scss'
//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent, lazy, Suspense } from 'react'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxContent from 'Shared/components/PuxContent/PuxContent'
import PuxMetaTagsGatsby from 'Root/Shared/utils/PuxMetaTagsGatsby'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import { GenericPageProps, IEasySoftwareChangeLog } from 'Root/Shared/queries/page-queries'

const ChangeLogTable = lazy(() => import(`./ChangeLogTable`))

const ChangeLogPage: FunctionComponent<GenericPageProps<IEasySoftwareChangeLog>> = (props) => {
  const pageData = props.pageContext.pageData
  const breadcrumbsData = props.pageContext.breadcrumbs
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const changeLog = props.pageContext.changeLog

  const buildChangeLogTable = (version: string, key: number) => {
    const dataByVersion =
      changeLog?.data?.filter(
        (item) => item.version === version
      ) ?? null

    if (dataByVersion !== null) {
      return (
        <Suspense fallback={<></>}>
          <ChangeLogTable data={dataByVersion} version={version} key={key} />
        </Suspense>
      )
    }

    return null
  }

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
    >
      <div className='ChangeLog'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <div className='Container'>
          <PuxContent sideCards={props.pageContext.sideCardWidgets}>
            <h1 className='ChangeLog-title'>{pageData.displayText}</h1>

            <div className='ChangeLog-grid'>
              {changeLog?.versions?.map((version, idx) =>
                buildChangeLogTable(version, idx)
              )}
            </div>
          </PuxContent>
        </div>
      </div>
    </Layout>
  )
}

export default ChangeLogPage

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareChangeLog>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}
