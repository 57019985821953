import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'
import EasySoftwareSecondaryNavigation, { EasySoftwareSecondaryNavigationType } from '../EasySoftwareSecondaryNavigation/EasySoftwareSecondaryNavigation'


export interface WidgetEasySoftwareSecondaryNavigationType extends WidgetBase, EasySoftwareSecondaryNavigationType {
  contentType: 'EasySoftwareSecondaryNavigation'
}

const WidgetEasySoftwareSecondaryNavigation: FunctionComponent<WidgetEasySoftwareSecondaryNavigationType> = (props) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <EasySoftwareSecondaryNavigation
      bag={props.bag}
      contentItemId={props.contentItemId}
      contentType={props.contentType}
      secondaryNavigationTitle={props.secondaryNavigationTitle}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareSecondaryNavigation
