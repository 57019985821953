/* eslint-disable prettier/prettier */
import './PuxRepeater.scss'

import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import React, {
  FunctionComponent,
  lazy,
  ReactNode,
  Suspense,
  useEffect,
} from 'react'
import { dt } from 'Root/Shared/utils/dynamicRS'
import { t } from 'ttag'

import { ArticlePartType } from './PuxRepeaterParts/ArticlePart'
import { CaseStudyPartType } from './PuxRepeaterParts/CaseStudyPart'
import { FaqPartType } from './PuxRepeaterParts/FaqPart'
import { FunctionPartType } from './PuxRepeaterParts/FunctionPart'
import { KnowledgeBaseArticlePartType } from './PuxRepeaterParts/KnowledgeBaseArticlePart'
import { NewsPartType } from './PuxRepeaterParts/NewsPart'
import { PartnerPartType } from './PuxRepeaterParts/PartnerPart'
import { TestimonialPartType } from './PuxRepeaterParts/TestimonialPart'
import { VideoTutorialPartType } from './PuxRepeaterParts/VideoTutorialPart'
import PuxLink from 'Shared/utils/PuxLink'
import { RepeaterContentType } from '../../queries/repeater-queries'
import PartnerFilter from './PuxRepeaterFilters/PartnerFilter'
import VideoTutorialFilter from './PuxRepeaterFilters/VideoTutorialFilter'

const ArticlePart = lazy(() => import(`./PuxRepeaterParts/ArticlePart`))
const CaseStudyPart = lazy(() => import(`./PuxRepeaterParts/CaseStudyPart`))
const FaqPart = lazy(() => import(`./PuxRepeaterParts/FaqPart`))
const FunctionPart = lazy(() => import(`./PuxRepeaterParts/FunctionPart`))
const KnowledgeBaseArticlePart = lazy(
  () => import(`./PuxRepeaterParts/KnowledgeBaseArticlePart`)
)
const NewsPart = lazy(() => import(`./PuxRepeaterParts/NewsPart`))
const PartnerPart = lazy(() => import(`./PuxRepeaterParts/PartnerPart`))
const TestimonialPart = lazy(() => import(`./PuxRepeaterParts/TestimonialPart`))
const VideoTutorialPart = lazy(
  () => import(`./PuxRepeaterParts/VideoTutorialPart`)
)

export type TaxonomyContentItemType =
  | ArticlePartType
  | FunctionPartType
  | CaseStudyPartType
  | NewsPartType
  | FaqPartType
  | VideoTutorialPartType
  | PartnerPartType
  | TestimonialPartType
  | KnowledgeBaseArticlePartType

interface FilterType {
  [field: string]: string
}

export interface BindingTableType {
  id: string
  field: string
}

export interface FilterInputType {
  completeData: TaxonomyContentItemType[]
  filteredData: TaxonomyContentItemType[]
  updateUrl: any
  clearFilter: any
  activeFilters: FilterType
  setBindingTable: any
}

export interface PuxRepeaterType {
  repeaterData: TaxonomyContentItemType[]
  simpleRepeaterPageSize: number
  simpleRepeaterPagerOptions: 'pages' | 'loadmore' | 'showall'
  simpleRepeaterOnePageOnly: boolean
  simpleRepeaterFallbackCulture: {
    html: string
  }
  puxSimpleFilter: {
    simpleFilterTermIDs: any
    simpleFilterExplicitIDs: any
    simpleFilterContentType: RepeaterContentType
    simpleFilterSortByIDs: boolean
    puxFilterIsHidden: boolean
  }
  contentItemId?: string
}

export function getDataByKey(
  data: TaxonomyContentItemType[],
  key: string,
  resourceString: string
) {
  const dataByKey: string[] = []
  const cleanedDataForSelect: object[] = []

  if (!data) {
    return null
  }

  data?.map((item) => {
    const itemByKey: string | string[] =
      item[key as keyof TaxonomyContentItemType]

    if (!itemByKey || itemByKey === `none`) {
      return
    }

    if (typeof itemByKey === `string`) {
      dataByKey.push(itemByKey)
    } else {
      const itemByKeyArray = itemByKey as string[]
      itemByKeyArray?.map((itemFromArray) => dataByKey.push(itemFromArray))
    }
  })

  const cleanedData = [...new Set(dataByKey.filter((item) => item !== null))]

  cleanedDataForSelect.push({
    value: ``,
    label: resourceString,
  })

  cleanedData?.sort()?.map((item) =>
    cleanedDataForSelect.push({
      value: item,
      label: dt(item),
    })
  )

  return cleanedDataForSelect
}

export function getLocalizedSelectValue(
  selectValue: string,
  defaultLabel: string
) {
  if (selectValue !== `` && selectValue !== undefined) {
    const rslabel = dt(selectValue)

    return {
      value: selectValue,
      label: rslabel,
    }
  }

  return {
    value: selectValue,
    label: dt(defaultLabel),
  }
}

let bindingTable: null | BindingTableType[] = null

const PuxRepeater: FunctionComponent<PuxRepeaterType> = (props) => {
  const location = useLocation()
  const itemsOriginal = props.repeaterData

  const getDefaultPage = () => {
    let defaultPage: string
    if (
      props.simpleRepeaterPagerOptions === `pages` &&
      props.simpleRepeaterOnePageOnly === false
    ) {
      defaultPage = new URLSearchParams(location.search).get(`page`) ?? `1`
    } else {
      defaultPage = `1`
    }

    return Number.parseInt(defaultPage)
  }

  const initialState = {
    items: props.repeaterData ?? [],
    filters: {},
    itemsCount: props.repeaterData?.length ?? 0,
    currentPage: getDefaultPage(),
    layoutClass: ``,
  }

  const [state, setState] = React.useState(initialState)

  const getPageSize = () => {
    if (
      props.simpleRepeaterPagerOptions === `showall` ||
      props.simpleRepeaterPagerOptions === null
    ) {
      return initialState.itemsCount
    } else {
      return Math.min(initialState.itemsCount, props.simpleRepeaterPageSize)
    }
  }

  const pageSize = getPageSize()

  const getPageCount = () => {
    const count = Math.ceil(state.itemsCount / pageSize)

    if (!Number.isNaN(count)) {
      return count
    }

    return 0
  }

  const getPagedDataArray = (data: TaxonomyContentItemType[]) => {
    if (data?.length) {
      return data.reduce(
        (resultArray: TaxonomyContentItemType[][], item, index) => {
          const pageIndex = Math.floor(index / pageSize)

          if (!resultArray[pageIndex]) {
            resultArray[pageIndex] = []
          }

          resultArray[pageIndex].push(item)

          return resultArray
        },
        []
      )
    }

    return [[]]
  }

  const buildPagedData = (page: number) => {
    const pagedDataArray = getPagedDataArray(state.items)
    let pagedItems = pagedDataArray[0] ?? []

    if (page != 1 && props.simpleRepeaterOnePageOnly === false) {
      if (props.simpleRepeaterPagerOptions === `pages`) {
        pagedItems.length = 0
      }
      pagedItems = pagedItems.concat(pagedDataArray[page - 1])
    }

    return pagedItems?.map((part, idx) => {
      switch (part?.contentType) {
        case `EasySoftwareArticle`:
          return (
            <Suspense key={idx} fallback={<div> Loading...</div>}>
              <ArticlePart {...part} />
            </Suspense>
          )

        case `EasySoftwareFunction`:
          return (
            <Suspense key={idx} fallback={<></>}>
              <FunctionPart {...part} />
            </Suspense>
          )
        case `EasySoftwareCaseStudy`:
          return (
            <Suspense key={idx} fallback={<></>}>
              <CaseStudyPart {...part} />
            </Suspense>
          )
        case `EasySoftwareNews`:
          return (
            <Suspense key={idx} fallback={<></>}>
              <NewsPart {...part} />
            </Suspense>
          )
        case `EasySoftwareFaq`:
          return (
            <Suspense key={idx} fallback={<></>}>
              <FaqPart {...part} />
            </Suspense>
          )
        case `EasySoftwareVideoTutorial`:
          return (
            <Suspense key={idx} fallback={<></>}>
              <VideoTutorialPart {...part} />
            </Suspense>
          )
        case `EasySoftwarePartner`:
          return (
            <Suspense key={idx} fallback={<></>}>
              <PartnerPart {...part} />
            </Suspense>
          )
        case `EasySoftwareTestimonial`:
          return (
            <Suspense key={idx} fallback={<></>}>
              <TestimonialPart {...part} />
            </Suspense>
          )
        case `EasySoftwareKnowledgeBaseArticle`:
          return (
            <Suspense key={idx} fallback={<></>}>
              <KnowledgeBaseArticlePart {...part} />
            </Suspense>
          )
        default:
          return null
      }
    })
  }

  // does not work correctly, but it is not used in ES anyway..
  const clearFilter = () => {
    const url = new URL(location.href)

    const paramKeys: string[] = []
    //@ts-ignore
    for (const key of url.searchParams.keys()) {
      paramKeys.push(key)
    }

    const filteredKeys = paramKeys.filter((key) => key.includes(`filter`))
    filteredKeys?.map((key) => url.searchParams.delete(key))

    setState({
      ...state,
      items: initialState.items,
      itemsCount: initialState.itemsCount,
      filters: initialState.filters,
    })

    navigate(url.pathname + url.search)
  }

  const setFilter = (filteredFields, origItems) => {
    const newFilters = initialState.filters
    let newItems = origItems

    if (filteredFields.length) {
      filteredFields?.map((filteredField) => {
        newFilters[filteredField.field] = filteredField.param
      })

      Object.keys(newFilters).forEach((filter) => {
        if (newFilters[filter] && newFilters[filter].length) {
          newItems = newItems.filter((p) => {
            const itemFilteredField: string | string[] =
              p[filter as keyof TaxonomyContentItemType]

            if (
              typeof itemFilteredField === `string` &&
              itemFilteredField == newFilters[filter]
            ) {
              return true
            }

            if (
              itemFilteredField &&
              itemFilteredField.includes(newFilters[filter])
            ) {
              return true
            }

            return false
          })
        }
      })
    }

    return {
      filters: newFilters,
      items: newItems,
      itemsCount: newItems?.length ?? 0,
    }
  }

  const updateUrl = (value: string, key: string) => {
    const url = new URL(location.href)
    url.searchParams.set(`page`, `1`)

    if (value === ``) {
      url.searchParams.delete(key)
    } else {
      url.searchParams.set(key, value)
    }

    navigate(url.pathname + url.search)
  }

  const setBindingTableHandler = (table: BindingTableType[]) => {
    bindingTable = table
  }

  const getFilter = (type: string) => {
    switch (type) {
      case `EasySoftwareVideoTutorial`:
        return (
          <VideoTutorialFilter
            completeData={itemsOriginal}
            filteredData={state.items}
            updateUrl={updateUrl}
            activeFilters={state.filters}
            clearFilter={clearFilter}
            setBindingTable={setBindingTableHandler}
          />
        )
      case `EasySoftwarePartner`:
        return (
          <PartnerFilter
            completeData={itemsOriginal}
            filteredData={state.items}
            updateUrl={updateUrl}
            activeFilters={state.filters}
            clearFilter={clearFilter}
            setBindingTable={setBindingTableHandler}
          />
        )
      default:
        return null
    }
  }

  const loadMore = () => {
    // setCurrentPage(currentPage + 1)
    setState({
      ...state,
      currentPage: state.currentPage + 1,
    })
  }

  useEffect(() => {
    const getLayoutClass = (contentType: string): string => {
      if (contentType) {
        const lowerFirstLetter =
          contentType.charAt(0).toLowerCase() + contentType.slice(1)

        return ` hasGrid Repeater--${lowerFirstLetter}`
      }

      return ` hasGrid ${state.layoutClass}`
    }

    let newState = {
      items: state.items,
      filters: state.filters,
      itemsCount: state.itemsCount,
      currentPage: state.currentPage,
      layoutClass: getLayoutClass(state.items[0]?.contentType),
    }

    const searchParams = new URLSearchParams(location.search)
    if (props.simpleRepeaterPagerOptions === `pages`) {
      newState.currentPage = Number.parseInt(searchParams.get(`page`) ?? `1`)
    }

    if (!props.puxSimpleFilter.puxFilterIsHidden) {
      const paramKeys: string[] = []
      const filteredFields: any[] = []
      //@ts-ignore
      for (const key of searchParams.keys()) {
        paramKeys.push(key)
      }

      const filteredKeys = paramKeys.filter((key) => key.includes(`filter`))

      filteredKeys?.map((key) => {
        if (!bindingTable) {
          return
        }

        const param: string | null = searchParams.get(key)
        const field: string = bindingTable[key]?.field

        if (field) {
          filteredFields.push({
            field,
            param,
          })
        }
      })

      newState = {
        ...newState,
        ...setFilter(filteredFields, itemsOriginal),
      }
    }

    if (
      location.search.includes(`page`) &&
      props.simpleRepeaterPagerOptions === `pages`
    ) {
      document
        .querySelector(`#repeater-${props.contentItemId}`)
        ?.scrollIntoView()
    }

    setState(newState)
  }, [location.search])

  return (
    <div
      className={`Repeater` + state.layoutClass}
      id={`repeater-${props.contentItemId}`}
    >
      {!props.puxSimpleFilter.puxFilterIsHidden && (
        <div className={`Repeater-filter`}>
          {itemsOriginal &&
            itemsOriginal.length > 0 &&
            getFilter(itemsOriginal[0].contentType)}
        </div>
      )}
      <div className={`Repeater-grid`}>{buildPagedData(state.currentPage)}</div>
      {!props.simpleRepeaterOnePageOnly && (
        <PuxRepeaterPager
          pageCount={getPageCount()}
          currentPage={state.currentPage}
          pagerOptions={props.simpleRepeaterPagerOptions}
          loadMoreHandler={loadMore}
        />
      )}
    </div>
  )
}

interface PuxRepeaterPagerType {
  pageCount: number
  currentPage: number
  pagerOptions: 'pages' | 'loadmore' | 'showall'
  loadMoreHandler: () => void
}

const PuxRepeaterPager: FunctionComponent<PuxRepeaterPagerType> = (props) => {
  const location = useLocation()

  const getPagerArray = (pageCount: number): any[] => {
    if (pageCount <= 0) {
      pageCount = 1
    }

    try {
      const buttonsArray = Array.apply(null, Array(pageCount))
      return buttonsArray
    } catch (error) {
      console.error(error);
      const buttonsArray = Array.apply(null, Array(1))
      return buttonsArray
    }
  }

  const getPagerLink = (page: number) => {
    if (location.href !== undefined) {
      const url = new URL(location.href)
      url.searchParams.set(`page`, page.toString())

      return url.pathname + url.search
    }

    return ``
  }

  const getPagerButton = (page: number, currentPage: number): ReactNode => {
    if (location.href !== undefined) {
      return (
        <PuxLink
          key={page}
          to={getPagerLink(page)}
          className={`Button Button--primary Button--repeaterPager${currentPage == page ? ` is-active` : ``
            }`}
        >
          {page}
        </PuxLink>
      )
    }

    return null
  }

  const getPagerSpacer = (page: number): ReactNode => {
    return (
      <div
        className={`Button Button--primary Button--repeaterPager Button--repeaterPagerSpacer`}
      >
        ...
      </div>
    )
  }

  const buildPager = (data: any[]): ReactNode => {
    const pagesCount = data.length

    if (pagesCount <= 1) return null

    return data?.map((page, idx) => {
      page = idx + 1
      if (page < 2) return getPagerButton(page, props.currentPage)

      if (props.currentPage > 3 && page == 2) return getPagerSpacer(page)

      if (
        page == props.currentPage ||
        page == props.currentPage - 1 ||
        page == props.currentPage + 1
      )
        return getPagerButton(page, props.currentPage)

      if (props.currentPage < pagesCount - 2 && page == pagesCount - 1)
        return getPagerSpacer(page)

      if (page > pagesCount - 1) return getPagerButton(page, props.currentPage)
    })
  }

  if (
    props.pagerOptions === `loadmore` &&
    props.currentPage < props.pageCount
  ) {
    return (
      <div className={`Repeater-buttons`}>
        <button
          className={`Button Button--large Button--blue Button--primary`}
          onClick={() => props.loadMoreHandler()}
        >
          {t`Redmine.Repeater.LoadMore`}
        </button>
      </div>
    )
  }

  if (props.pagerOptions === `pages`) {
    return (
      <div className='Repeater-pager'>
        {buildPager(getPagerArray(props.pageCount))}
      </div>
    )
  }

  return null
}

export default PuxRepeater
