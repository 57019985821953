import { getBuilderService } from "./data/puxBuilderService"
import { puxGraphqlHelper } from "./data/puxGraphqlHelper"

export interface KnowledgeBaseMenuItemType {
  isActive: boolean
  displayText: string
  displayTextWithoutNumber: string
  path: string
  menuItemsList: KnowledgeBaseMenuItemType[]
}

interface KnowledgeBaseRawDataType {
  data: {
    orchard: Data
  }
}

interface Data {
  menu: Menu[]
}

interface Menu {
  menuItemsList: CategoryMenuItemsList
}

interface CategoryMenuItemsList {
  menuItems: CategoryMenuItem[]
}

interface CategoryMenuItem {
  displayText: string
  render: string
  menuItemsList?: CategoryMenuItemsList
}

const getKnowledgeBaseData = async (
  categoryPath?: string
): Promise<KnowledgeBaseMenuItemType[]> => {
  const fetchedData = await fetchKnowledgeBaseData()
  const parsedData = parseKnowledgeBaseData(fetchedData, categoryPath)

  return parsedData
}

export default getKnowledgeBaseData

export const getKnowledgeBaseDataByCategoryPath = async (
  categoryPath: string
): Promise<KnowledgeBaseMenuItemType[]> => {
  const fetchedAndParsedData = await getKnowledgeBaseData()
  const filteredData = filterKnowledgeBaseDataByCategoryPath(
    categoryPath,
    fetchedAndParsedData
  )

  return filteredData
}

export const filterKnowledgeBaseDataByCategoryPath = (
  categoryPath: string,
  data: KnowledgeBaseMenuItemType[]
): KnowledgeBaseMenuItemType[] => {
  const filteredData: KnowledgeBaseMenuItemType[] = []
  const filteredCategory = data.filter((item) =>
    item.path.includes(categoryPath)
  )

  filteredCategory.map((category) =>
    filteredData.concat(category.menuItemsList)
  )

  return filteredData
}

const fetchKnowledgeBaseData = async (): Promise<KnowledgeBaseRawDataType | undefined> => {
  const { locale } = getBuilderService()

  const knowledgeBaseMenuQuery = `{
    orchard {
      menu(where: { alias: { alias_contains: "knowledgebase-navigation" }, localization: {culture: "${locale.isoCode.toLowerCase()}"}}) {
        menuItemsList {
          menuItems {
            ... on Orchard_ContentMenuItem {
              displayText
              render
              menuItemsList {
                menuItems {
                  ... on Orchard_ContentMenuItem {
                    displayText
                    render
                  }
                }
              }
            }
          }
        }
      }
    }
  }`

  const result = await puxGraphqlHelper<KnowledgeBaseRawDataType>(
    knowledgeBaseMenuQuery,
    {
      errorMetadata: {
        fromFunction: `Error while running GraphQL query for knowledgeBaseMenuQuery in fetchKnowledgeBaseData().`,
      }
    }
  )

  return result
}

const parseKnowledgeBaseData = (
  rawData: KnowledgeBaseRawDataType | undefined,
  activeUrl?: string
) => {
  const parsedData: KnowledgeBaseMenuItemType[] = []

  if (rawData && rawData.menu && rawData.menu[0]) {
    rawData.menu[0].menuItemsList?.menuItems.map((item) =>
      parsedData.push(parseKnowledgeBaseMenuItemsRecursive(item))
    )
  }

  if (activeUrl) {
    const foundActiveUrl: boolean[] = []

    for (const data of parsedData) {
      if (data.path.includes(activeUrl)) {
        foundActiveUrl.push(true)
        data.isActive = true
      } else {
        if (data.menuItemsList && JSON.stringify(data.menuItemsList).includes(activeUrl)) {
          foundActiveUrl.push(true)
          data.isActive = true
        }
      }

      if (foundActiveUrl.find((item) => item === true)) {
        break
      }
    }
  }

  return parsedData
}

const parseKnowledgeBaseMenuItemsRecursive = (
  menuItem: CategoryMenuItem
): KnowledgeBaseMenuItemType => {
  const parsedItem: KnowledgeBaseMenuItemType = {
    isActive: false,
    displayText: menuItem.displayText,
    displayTextWithoutNumber: menuItem.displayText.split(`. `)[1],
    path: JSON.parse(menuItem.render).url as string,
    menuItemsList: [],
  }

  if (menuItem.menuItemsList?.menuItems) {
    menuItem.menuItemsList?.menuItems.map((subItem: CategoryMenuItem) =>
      parsedItem.menuItemsList?.push(
        parseKnowledgeBaseMenuItemsRecursive(subItem)
      )
    )
  }

  return parsedItem
}
