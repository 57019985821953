import './PuxModal.scss'

import Modal from '@material-ui/core/Modal'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { FunctionComponent } from 'react'

import CloseIcon from '../PuxIconBuilder/Icons/CloseIcon'

interface PuxModalType {
  id: string
  children: React.ReactNode
}

const rootClass = `Modal`

export const getModalTriggerId = (id?: string): string => {
  return typeof id === `string` ? `modal-trigger-${id}` : `missing-trigger-id`
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: `absolute`,
      width: 1180,
      backgroundColor: theme.palette.background.paper,
      boxShadow: `7px 7px 50px rgba(80, 89, 111, 0.3)`,
      padding: theme.spacing(2, 4, 3),
    },
  })
)

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const PuxModal: FunctionComponent<PuxModalType> = (props) => {
  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    if (typeof document !== `undefined`) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: `openmodal`,
        })
      }
    }
    setOpen(true)
  }

  return (
    <div className={`${rootClass}`}>
      <Modal open={open} onClose={handleClose}>
        <div
          className={`${rootClass}-paper ${classes.paper}`}
          style={modalStyle}
        >
          <div className={`${rootClass}-close`} onClick={handleClose}>
            <CloseIcon
              color={{ colorPrimary: `#0080ff` }}
              hoverColor={{ colorPrimary: `#50596f` }}
            />
          </div>
          <div className={`${rootClass}-content`}>{props.children}</div>
        </div>
      </Modal>

      <button
        className={`${rootClass}-trigger`}
        onClick={() => handleOpen()}
        id={getModalTriggerId(props.id)}
      >
        Open
      </button>
    </div>
  )
}

export default PuxModal
