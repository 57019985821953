import React from 'react'

import { WidgetProperitesPart } from '../builders/WidgetBuilder'

export function getCssClass(cssClass: string | undefined): string {
  return cssClass ? ` ${cssClass}` : ``
}

export function getMetaCssClass(
  cssClass: string | undefined,
  prefix: string
): string {
  return cssClass ? ` ${prefix}${cssClass}` : ``
}

export function getClass(
  size: number,
  properties?: WidgetProperitesPart
): string {
  return size
    ? getMetaCssClass(size?.toString(), `FormGroup--`)
    : undefined +
    getCssClass(properties?.widgetPropertiesPartPaddingTop) +
    getCssClass(properties?.widgetPropertiesPartPaddingBottom)
}

export function getRequiredField(isRequired: boolean): JSX.Element | null {
  return isRequired ? <span className='required-star'></span> : null
}
