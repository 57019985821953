import './FaqDetail.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import PuxContent from 'Root/Shared/components/PuxContent/PuxContent'
import PuxStructuredData from 'Root/Shared/components/PuxStructuredData/PuxStructuredData'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxWysiwyg from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { GenericPageProps, IEasySoftwareFaq } from 'Shared/queries/page-queries'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

function getFaqJson(item: IEasySoftwareFaq) {
  const cleanAnswer = item.faqAnswer.html.replace(/<[^>]*>/g, '');
  const prefixUrl = process.env.GATSBY_WEBSITE_URL ?? ""

  const json =
  {
    "@context": "https://schema.org",
    "@type": "QAPage",
    mainEntity: {
      "@type": "Question",
      name: item.displayText,
      text: item.displayText,
      answerCount: 1,
      author: {
        "@type": "Organization",
        name: "EasySoftware",
        url: prefixUrl
      },
      acceptedAnswer: {
        "@type": "Answer",
        text: cleanAnswer,
      },
    }
  }

  return json
}


const FaqDetail: FunctionComponent<GenericPageProps<IEasySoftwareFaq>> = (props) => {
  const pageData = props.pageContext.pageData
  const breadcrumbsData = props.pageContext.breadcrumbs
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const editorPath = `faqAnswer,html`

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
      pageContentItemId={pageData.contentItemId}
    >
      <PuxStructuredData structuredData={getFaqJson(pageData)} />
      <div className='Container'>
        <div className={`FaqDetail`}>
          <EasySoftwareBreadcrumbs items={breadcrumbsData} />
          <PuxContent
            contentModifier='faqDetail'
            sideCards={props.pageContext.sideCardWidgets}
          >
            <h1 className={`FaqDetail-title`}>
              <EasySoftwareEditorContainer
                type="input"
                pageContentItemId={pageData.contentItemId}
                content={pageData.displayText}
                editorPath={"displayText"}
                wysiwygId={pageData.contentItemId + `-title`}
              />
            </h1>
            <EasySoftwareEditorContainer
              pageContentItemId={pageData.contentItemId}
              content={pageData.faqAnswer}
              editorPath={editorPath}
              wysiwygId={pageData.contentItemId + `-answer`}
            />
          </PuxContent>
        </div>
      </div>
    </Layout>
  )
}

export default FaqDetail

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareFaq>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}
