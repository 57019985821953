import { getBuilderService } from "./data/puxBuilderService"
import { puxGraphqlHelper } from "./data/puxGraphqlHelper"

interface ContentItemQueryResult {
  easySoftwareNews: ContentItem[]
}

interface ContentItem {
  contentItemId: string
}

export const getNewsContentIds = async (): Promise<string[]> => {
  const { locale } = getBuilderService()

  const contentItemQuery = `
      {
        orchard {
          easySoftwareNews(where: {localization: {culture: "${locale.isoCode.toLowerCase()}"}}) {
            contentItemId
          }
        }
      }
  `

  const contentItemNode = await puxGraphqlHelper<ContentItemQueryResult>(contentItemQuery, {
    errorMetadata:
    {
      message: `Error while running GraphQL query in getNewsCategories`,
    }
  })

  if (!contentItemNode?.easySoftwareNews) {
    throw `Error: contentItemNode.contentItem is null or undefined in getNewsCategories`
  }

  return contentItemNode.easySoftwareNews.map(item => item.contentItemId)
}

export const getNewsContentIdsByAuthorId = async (authorId: string): Promise<string[]> => {
  const { locale } = getBuilderService()

  const contentItemQuery = `
      {
        orchard {
          easySoftwareNews(where: {puxAuthor: {authorUserId: "${authorId}"}, localization: {culture: "${locale.isoCode.toLowerCase()}"}}) {
            contentItemId
          }
        }
      }
  `

  const contentItemNode = await puxGraphqlHelper<ContentItemQueryResult>(contentItemQuery, {
    errorMetadata:
    {
      message: `Error while running GraphQL query in getNewsContentIdsByAuthorId`,
    }
  })

  if (!contentItemNode?.easySoftwareNews) {
    throw `Error: contentItemNode.contentItem is null or undefined in getNewsContentIdsByAuthorId`
  }

  return contentItemNode.easySoftwareNews.map(item => item.contentItemId)
}
