import { ErrorMessage } from '@hookform/error-message'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { dt } from 'Root/Shared/utils/dynamicRS'
import { t } from 'ttag'

import { FormFieldMultipleType } from '../builders/FormBuilder'
import { WidgetProperitesPart } from '../builders/WidgetBuilder'
import FormErrorHelper from './FormErrorHelper'
import { getClass, getRequiredField } from './formFieldHelper'

const FormFieldRadioWidget: React.FC<{
  props: FormFieldMultipleType
  widgetProperties?: WidgetProperitesPart
}> = ({ props, widgetProperties }) => {
  const formElement = props?.puxFormElement
  const formValidation = props?.puxFormElementValidation
  const formOptions = props?.puxFormElementOptions

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const [groupValue, setGroupValue] = useState<string>(
    props.puxFormElement.formElementDefaultValue
  )

  return (
    <div
      className={`FormGroup ${
        formOptions?.formElementOptionsCssClass ?? ``
      } ${getClass(props?.metadata?.size, widgetProperties)} FormGroup--${
        props.multipleType
      }`}
    >
      <div className='label'>
        {formElement?.formElementLabel}
        {getRequiredField(
          props?.puxFormElementValidation?.formElementValidationRequired
        )}
      </div>
      <Controller
        name={formElement?.formElementName}
        control={control}
        rules={{
          required: formValidation.formElementValidationRequired
            ? dt(`PuxForm.Error.Required`)
            : false,
        }}
        render={({ field: fieldProps }) => (
          <div>
            {props?.multipleOptions?.split(`\r\n`)?.map((option, idx) => {
              const value = option?.substring(0, option.indexOf(`;`))
              const label = option?.substring(option.lastIndexOf(`;`) + 1)
              return (
                <div key={value}>
                  <input
                    {...fieldProps}
                    id={label + value}
                    type={props?.multipleType}
                    checked={groupValue === value}
                    value={value}
                    disabled={formOptions?.formElementOptionsDisabled}
                    onChange={(e) => {
                      setGroupValue(value)
                    }}
                  />
                  <label
                    htmlFor={label + value}
                    className='form-radio-item'
                    key={idx}
                  >
                    {label}
                  </label>
                </div>
              )
            })}
          </div>
        )}
      />

      <div className='form-radio-group'></div>
      <ErrorMessage
        render={(data) => <FormErrorHelper {...data} />}
        errors={errors}
        name={formElement?.formElementID ?? ``}
      />
    </div>
  )
}

export default FormFieldRadioWidget
