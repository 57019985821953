import './WidgetContainer.scss'

import React, { FunctionComponent } from 'react'
import {
  getAnimation,
  getAnimationWrapper,
} from 'Root/Shared/utils/getAnimation'

import {
  WidgetAnimationPart,
  WidgetMetadata,
  WidgetProperitesPart,
} from './WidgetBuilder'

export interface WidgetContainerProps
  extends WidgetProperitesPart,
  WidgetAnimationPart,
  WidgetMetadata {
  children: React.ReactNode
  fullWidth?: boolean
  thin?: boolean
  cropped?: boolean
  customClassName?: string
}

interface WidgetAttributes {
  className: string
  id?: string
}

const rootClass = `WidgetContainer`

const WidgetContainer: FunctionComponent<WidgetContainerProps> = (props) => {
  let className = rootClass

  className += props.fullWidth ? `` : ` WidgetContainer-padding`

  className += props.thin ? ` WidgetContainer-thin` : ``

  className += props.cropped ? ` WidgetContainer-cropped` : ``

  className += props.customClassName ? ` ${props.customClassName}` : ``

  props.widgetPropertiesPartMarginBottom
    ? (className += ` ${props.widgetPropertiesPartMarginBottom}`)
    : null
  props.widgetPropertiesPartMarginTop
    ? (className += ` ${props.widgetPropertiesPartMarginTop}`)
    : null
  props.widgetPropertiesPartPaddingBottom
    ? (className += ` ${props.widgetPropertiesPartPaddingBottom}`)
    : null
  props.widgetPropertiesPartPaddingTop
    ? (className += ` ${props.widgetPropertiesPartPaddingTop}`)
    : null
  props.widgetPropertiesPartMobileOrder
    ? (className += ` mobileOrder-${props.widgetPropertiesPartMobileOrder}`)
    : null
  props.size ? (className += ` WidgetContainer--${props.size}`) : null
  props.alignment ? (className += ` ${props.alignment}`) : null

  const widgetAttributes: WidgetAttributes = {
    className: className,
  }

  const widgetAnimation = getAnimation(props.widgetAnimationPartType)

  props.widgetPropertiesPartID
    ? (widgetAttributes.id = props.widgetPropertiesPartID)
    : null

  return (
    <div {...widgetAttributes}>
      {getAnimationWrapper(
        props.children,
        props.widgetAnimationPartIsEnabled,
        widgetAnimation,
        props.widgetAnimationPartFraction,
        props.widgetAnimationPartDuration,
        props.widgetAnimationPartDelay
      )}
    </div>
  )
}

export default WidgetContainer
