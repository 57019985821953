import './EasySoftwareIconBar.scss'

import React, { FunctionComponent } from 'react'

import EasySoftwareIconBarItem, {
  EasySoftwareIconBarItemType,
} from './EasySoftwareIconBarItem'

export interface EasySoftwareIconBarType {
  displayText: string
  backgroundColor: string
  iconBarIconList: {
    contentItems: [EasySoftwareIconBarItemType]
  }
}

const EasySoftwareIconBar: FunctionComponent<EasySoftwareIconBarType> = (
  props
) => {
  const classList = [`IconBar`]

  if (props.backgroundColor) classList.push(props.backgroundColor)

  return (
    <div className={classList.join(` `)}>
      <div className='Container'>
        <div className='IconBar-items'>
          {props.iconBarIconList &&
            props.iconBarIconList.contentItems &&
            props.iconBarIconList.contentItems.map((item, idx) => (
              <EasySoftwareIconBarItem {...item} key={idx} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareIconBar
