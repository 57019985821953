import React, { FunctionComponent } from 'react'

import background from '../../images/SideCard.svg'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareSideCardCustomType {
  contentType: 'EasySoftwareSideCardCustom'
  displayText: string
  contentItemId: string
  sideCardCustomButton: PuxButtonType
  sideCardCustomText: PuxWysiwygHtmlBodyType
  widgetOrder?: number
}

import './EasySoftwareSideCard.scss'


const EasySoftwareSideCardCustom: FunctionComponent<EasySoftwareSideCardCustomType> = (
  props
) => {
  return (
    <div className='Sidebar-box'>
      <img className='Sidebar-background' src={background} />
      <h3 className='Sidebar-box-title'>{props.displayText}</h3>
      <PuxWysiwyg
        className='Sidebar-box-perex'
        content={props.sideCardCustomText}
      />
      <PuxButton {...props.sideCardCustomButton} />
    </div>
  )
}

export default EasySoftwareSideCardCustom
