export function removeOrchardPrefixFromQuery(query: string): string {
    let newQuery

    if (query.includes("orchard {")) {
        newQuery = query
            .replace("orchard {", "")
            .replace(/... on Orchard_/g, "... on ")
            .replace(/}([^}]*)$/, "")
    } else {
        newQuery = query
    }

    return newQuery
}