import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxImageContent, {
  PuxImageContentType,
} from '../PuxImageContent/PuxImageContent'

export interface WidgetPuxImageContentType
  extends WidgetBase,
  PuxImageContentType {
  contentType: 'PuxImageContent'
}

const WidgetPuxImageContent: FunctionComponent<WidgetPuxImageContentType> = (
  props
) => {  
  return (
    <WidgetContainer
      {...props.puxWidgetProperties}
      {...props.puxWidgetAnimation}
      {...props.metadata}
    >
      <PuxImageContent
        imageContentText={props.imageContentText}
        imageContentCollapseContent={props.imageContentCollapseContent}
        imageContentImagePosition={props.imageContentImagePosition}
        imageContentTextAlign={props.imageContentTextAlign}
        imageContentButtonAlign={props.imageContentButtonAlign}
        puxMediaSelector={props.puxMediaSelector}
        imageContentImageMobile={props.imageContentImageMobile}
        imageContentGallery={props.imageContentGallery}
        puxButton={props.puxButton}
        puxVideo={props.puxVideo}
        puxImageContentMedia={props.puxImageContentMedia}
        pageContentItemId={props.pageContentItemId}
        editorPath={props.editorPath}
        contentItemId={props.contentItemId}
      />
    </WidgetContainer>
  )
}

export default WidgetPuxImageContent
