/* eslint-disable simple-import-sort/imports */
import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'
import { PuxWysiwygHtmlBodyType } from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'

import EasySoftwareEditorContainer from '../EasySoftwareEditorContainer/EasySoftwareEditorContainer'

export interface WidgetPuxWysiwygType extends WidgetBase {
  contentType: 'PuxWysiwyg'
  wysiwygSmallContainer: boolean
  htmlBody: PuxWysiwygHtmlBodyType,
  pageContentItemId: string
  editorPath: string
}

const WidgetPuxWysiwyg: FunctionComponent<WidgetPuxWysiwygType> = (props) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <EasySoftwareEditorContainer
      content={props.htmlBody}
      wysiwygId={props.contentItemId}
      small={props.wysiwygSmallContainer}
      pageContentItemId={props.pageContentItemId}
      editorPath={props.editorPath}
    />
  </WidgetContainer>
)
export default WidgetPuxWysiwyg
