import './EasySoftwareModules.scss'

import React, { FunctionComponent } from 'react'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import { SideCardNavigationLinkType } from 'Shared/components/PuxNavigation/PuxNavigation'
import PuxNavigation from 'Shared/components/PuxNavigation/PuxNavigation'
import { PuxWysiwygHtmlBodyType } from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { t } from 'ttag'

import Circle_1 from '../../../Shared/images/Circle 3.png'
import Circle_2 from '../../../Shared/images/Circle 5.png'

interface EasySoftwareModuleType {
  contentType: 'EasySoftwareModule'
  displayText: string
  moduleCore: PuxWysiwygHtmlBodyType
  moduleIcon: string
  moduleLinks: {
    contentItems: SideCardNavigationLinkType[]
  }
}

export interface EasySoftwareModulesType {
  displayText: string
  modulesItem: {
    cultureContentItems: EasySoftwareModuleType[]
  }
  pageContentItemId: string
  editorPath: string
  contentItemId: string
}

const EasySoftwareModules: FunctionComponent<EasySoftwareModulesType> = (
  props
) => {
  const moduleData =
    props.modulesItem?.cultureContentItems?.length > 0
      ? props.modulesItem?.cultureContentItems[0]
      : null
  if (!moduleData) {
    return null
  }

  return (
    <div className='EsModules'>
      <h2 className='EsModules-title'>{props.displayText}</h2>

      <div className='EsModules-columns'>
        <div className='EsModules-box'>
          <div className='EsModules-col'>
            <p className='EsModules-heading'>{t`Redmine.ModulesWidget.Core`}</p>
            <h4>{moduleData.displayText}</h4>
            <EasySoftwareEditorContainer
              pageContentItemId={props.pageContentItemId}
              content={moduleData.moduleCore}
              inline
              editorPath={props.editorPath}
              wysiwygId={props.contentItemId}
            />
          </div>
          <div className='EsModules-images'>
            <img className='EsModules-circle' src={Circle_1} />
          </div>
        </div>

        <span className='EsModules-icon'></span>

        <div className='EsModules-navigation'>
          <div className='EsModules-content'>
            <p className='EsModules-heading'>
              {t`Redmine.ModulesWidget.Modules`}
            </p>
            <img className='EsModules-circle' src={Circle_2} />
          </div>
          <PuxNavigation
            type={`widget`}
            items={moduleData.moduleLinks.contentItems}
          />
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareModules
