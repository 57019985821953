import './EasySoftwareInputEditor.scss'

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import EasySoftwareLightEditorBar from '../EasySoftwareEditorBar/EasySoftwareLightEditorBar'

export interface EasySoftwareInputEditorType {
  wysiwygId?: string
  className?: string
  inline?: boolean
  small?: boolean
  path?: string
  pageContentItemId?: string
  closeEditor: () => void
  saveEditor: (value: string, EditorRef: any, EditorButtonRef: any) => void
}

const EasySoftwareInputEditor: FunctionComponent<EasySoftwareInputEditorType> = (
  props
) => {
  const EditorButtonRef = useRef<HTMLButtonElement | null>(null)
  const EditorRef = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = useState("")

  const classes = [`InputEditor`]
  classes.push(props.inline ? `InputEditor--inline` : `InputEditor--spaced`)
  if (props.className) {
    classes.push(props.className)
  }
  if (props.small) {
    classes.push(`InputEditor--small`)
  }

  async function saveData() {
    let result = EditorRef.current?.value.trim() ?? ""

    await props.saveEditor(result, EditorRef, EditorButtonRef)
  }

  async function getData() {
    let content = await fetch(`${process.env.GATSBY_ORCHARD_API_URL}/puxapi/contentItem/getField?contentItemId=${props.pageContentItemId}&path=${props.path}`)
    .then((res) => {
      return res.text()
    })
    .catch((error) => {
      console.log(`Error saving editor data:`, error)
    })
    setValue(content ?? "")
  }

  useEffect(() => {
    getDataFunction()
  }, [])

  async function getDataFunction() {
    await getData()
  }

  return (
    <div className={classes.join(` `)}>
      <EasySoftwareLightEditorBar
        wysiwygOptions={{
          saveHandler: saveData,
          closeHandler: props.closeEditor,
          saveButtonRef: EditorButtonRef,
          id: `toolbar-${props.wysiwygId}`,
          active: true,
        }}
      />
      <input id={`wysiwyg-${props.wysiwygId}`} className='Input' ref={EditorRef} defaultValue={value} />
    </div>
  )
}

export default EasySoftwareInputEditor
